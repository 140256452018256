import { blueGrey } from '@material-ui/core/colors';
import colors from '../../utils/colors';

const { mainColor, grey } = colors;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(10),
    right: theme.spacing(6),
  },
  blue: {
    color: theme.palette.getContrastText(blueGrey[500]),
    backgroundColor: mainColor,
    fontFamily: [
      'Roboto',
    ],
  },
  marginTop: {
    marginTop: '24px',
  },
  sideModule: {
    maxHeight: '30vh',
    overflow: 'auto',
  },
  title: {
    color: grey,
  },
  mainTitle: {
    whiteSpace: 'none',
  },
  containerTitle: {
    justifyContent: 'center',
  },
  icon: {
    color: mainColor,
  },
});

export default styles;
