import moment from 'moment';
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';
import { formatQuestionValue, formatStringDate } from '../../../utils/functions';

export const exportData = (forms, formAnswers, questionTypes) => {
  const { name } = Object.values(forms).find((form) => form.id === Object.values(formAnswers)[0].formId);
  const object = Object.values(formAnswers).map((fa) => {
    const questionAnswers = (
      fa.questionAnswers()
        .sort((a, b) => a.question()?.index - b.question()?.index)
        .reduce((prev, current) => {
          const question = current.question();
          const questionTypeCode = Object.values(questionTypes).find((qt) => qt.id === question.questionTypeId).code;
          const questionValue = formatQuestionValue(questionTypeCode, current.value);

          return {
            ...prev,
            [question.text]: questionValue,
          };
        }, {})
    );

    return {
      Usuario: fa.entity()?.name,
      Fecha: formatStringDate(fa.date),
      Latitud: fa.latitude,
      Longitud: fa.longitude,
      Numero: fa.id,
      ...questionAnswers,
      Aprobadores: fa.approvals().map((approval) => approval.user()?.name || 'SIN NOMBRE').join(', '),
    };
  });

  const wb = XLSX.utils.book_new();
  let ws = XLSX.utils.json_to_sheet(object);
  // TODO: check filter headers
  ws['!autofilter'] = { ref: 'A1:Z1' };

  XLSX.utils.book_append_sheet(wb, ws, 'Respuestas');
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `respuestas ${name}.xlsx`);
};

export const getRangeFromPeriodNumber = {
  '-1': getTodayInterval,
  0: getTodayInterval,
  1: getYesterdayInterval,
  2: () => getLastDaysInterval(7),
  3: () => getLastDaysInterval(14),
  4: getThisWeekInterval,
  5: getLastWeekInterval,
  6: getThisMonthInterval,
  7: getPreviousMonthInterval,
};

function getTodayInterval() {
  const date = moment(new Date()).format('YYYY-MM-DD');
  return [date, date];
}

function getYesterdayInterval() {
  let date = new Date();
  date.setDate(date.getDate() - 1);
  date = moment(date).format('YYYY-MM-DD');
  return [date, date];
}

function getLastDaysInterval(days) {
  const endDate = moment(new Date()).format('YYYY-MM-DD');
  const startDate = new Date().setDate(new Date().getDate() - days);
  return [moment(startDate).format('YYYY-MM-DD'), endDate];
}

function getThisWeekInterval() {
  const startDate = new Date().setDate(
    new Date().getDate() - new Date().getDay() + (new Date().getDay() === 0 ? -6 : 1),
  );
  const endDate = new Date();
  return [moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD')];
}

function getLastWeekInterval() {
  let startDate = new Date();
  let endDate = new Date();
  // set to Monday of this week
  startDate.setDate(startDate.getDate() - ((startDate.getDay() + 6) % 7));
  // set to previous Monday
  startDate.setDate(startDate.getDate() - 7);

  // set to Monday of this week
  endDate.setDate(endDate.getDate() - ((endDate.getDay() + 6) % 7));
  // set to previous Sunday
  endDate.setDate(endDate.getDate() - 1);
  return [moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD')];
}

function getThisMonthInterval() {
  const startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const endDate = new Date();
  return [moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD')];
}

function getPreviousMonthInterval() {
  let endDate = new Date();
  endDate.setDate(0);
  const startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
  return [moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD')];
}
