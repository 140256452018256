import { call, put } from 'redux-saga/effects';
import { appActions } from 'commons/reducer';

export default function* controlledCall(
  toYield,
  path,
  body = undefined,
  succesFunction,
  failFunction = appActions.setError,
  isOpenFile = false,
) {
  const result = yield call(toYield, path, body);
  if (isOpenFile) {
    if (!result.ok) {
      let data = null;
      yield result.json().then(body => (data = body));
      yield put(failFunction(data));
    }
  } else {
    const nextAction = result.error ? failFunction : succesFunction;
    if (result.error) {
      yield put(nextAction(result.errorMsg));
    } else {
      yield put(nextAction(result));
    }
    return result;
  }
}
