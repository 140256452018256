/* eslint-disable react/jsx-filename-extension */
// @ts-nocheck
import React from 'react';
// material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import ButtonWithLoader from '../../containers/ButtonWithLoader';
// styles
// import styles from './styles';

// const useStyles = makeStyles(styles);

const DeleteModal = (props) => {
  const {
    toggleForm, isOpen, submit, buttonId,
  } = props;

  return (
    <Dialog fullWidth onClose={toggleForm} aria-labelledby="simple-dialog-title" open={isOpen}>
      <DialogTitle>Confirmar eliminación</DialogTitle>
      <DialogContent>
        <Typography>¿Esta seguro que desea eliminar este registro?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleForm} color="primary" variant="outlined">
          Cancelar
        </Button>
        <ButtonWithLoader onClick={submit} autoFocus buttonId={buttonId}>
          Confirmar
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
