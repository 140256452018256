// @ts-nocheck
import React from 'react';
// material
import logo from 'utils/images/123x50.png';
import Grid from '@material-ui/core/Grid';

const Footer = (props) => (
  <div className="footer no-print">
    <Grid
      container
      direction="row"
      justify="flex-end"
      alignItems="center"
    >
      <Grid item>
        <img src={logo} alt="logo" className="footer-logo" />
      </Grid>
    </Grid>
  </div>
);

export default Footer;
