import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import colors from '../../../utils/colors';

const CustomTooltip = withStyles({
  tooltip: {
    fontFamily: 'Roboto',
    backgroundColor: colors.grey
  }
})(Tooltip);

export default CustomTooltip;