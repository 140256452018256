/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'login/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;

// Global constants for saga
export const LOGIN = {
  LOGIN_USER: `${PATH}FETCH_LOGIN_USER`,
  GET_USER: `${PATH}GET_USER`,
  GET_USER_SUCCESS: `${PATH}GET_USER_SUCCESS`,
  GET_USER_FAIL: `${PATH}GET_USER_FAIL`,
};

// actions
export const loginActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  // api
  getUser: createAction(LOGIN.GET_USER),
  login: createAction(LOGIN.LOGIN_USER),
};

const initialState = {
  error: false,
};

const login = createReducer(initialState, {
  [`${DISABLE_ERRORS}`](state, action) {
    state.error = false;
  },
  // api
});

export default login;
