/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-unresolved */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import moment from 'moment';
// Project
import { selectState } from 'utils/reduxActions';
import { formActions } from 'screens/Form/reducer';
import { ReactReduxContext } from 'config/configureStore';
import connectSaga from 'utils/saga';
import './styles.scss';
import './form.css';
import colors from 'utils/colors';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, Card, CardContent, Container, Typography, withStyles, MenuItem,
} from '@material-ui/core';
import Filters from 'screens/Form/components/Filters';
import FormAnswers from 'screens/Form/components/FormAnswers';
import { exportData, getRangeFromPeriodNumber } from 'screens/Form/util';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styles from './styles';
import saga from './saga';
import PrintableForms from './components/PrintableForms';

const useStyles = makeStyles(styles);

const Form = (props) => {
  const { actions, controls, objects } = props;
  const classes = useStyles();
  const history = useHistory();
  const { mainColor } = colors;
  const [isPrintable, setIsPrintable] = useState(false);
  const [toPrintFormAnswersIds, setToPrintFormsIds] = useState(new Set());

  const defaultFilters = {
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    currentPeriodNumber: "0",
    selectedFormAnswer: null,
    selectedQuestionId: null,
    selectedAnswerValue: null,
    questionAnswers: [],
  };

  // --------- Extra functions ----------------

  // --------Life Cycle functions ---------
  useEffect(() => {
    const formid = (new URLSearchParams(window.location.search)).get('formid');
    onFormIdChange(formid);

    if (Object.keys(objects.forms).length === 0) {
      history.push('/forms');
      return;
    }

    actions.getFormAnswersFromApi(formid);
  }, []);

  // ------------- onChange functions -------------
  const onFormIdChange = (formid, fromSelect = false) => {
    actions.changeControls({ ...defaultFilters, selectedFormId: formid });
    actions.setFormAnswer(null);

    // Reset formAnswers objects
    setTimeout(() => {
      if (fromSelect) {
        history.push(`/form?formid=${formid}`);
      }
    }, 100);
  };

  const onDateFilterChange = (date, type) => {
    const control = type === 'start' ? 'startDate' : 'endDate';

    actions.changeControls({
      [control]: date.target.value,
      currentPeriodNumber: -1,
    });
  };

  const onPeriodChange = ({ target: { value } }) => {
    const range = getRangeFromPeriodNumber[value]();

    actions.changeControls({
      startDate: range[0],
      endDate: range[1],
      currentPeriodNumber: value,
    });
  };

  const onQuestionChange = ({ target: { value } }) => {
    actions.changeControls({
      selectedQuestionId: value,
      questionAnswers: Object.values(objects.questionOptions).filter(item => item?.questionId === value),
      selectedAnswerValue: null,
    })
  }

  const onAnswerChange = ( { target: { value } }) => {
    actions.changeControls({
      selectedAnswerValue: value,
    })
  }

  const updateFormAnswerList = () => {
    actions.getFormAnswersFromApi(controls.selectedFormId);
    actions.setFormAnswer(null);
    resetButtons();
  }

  const onClickSearch = () => {
    updateFormAnswerList();
  }

  const onClickClean = () => {
    actions.changeControls({ ...defaultFilters });
    updateFormAnswerList();
  }

  // ------------- Reset Button function -------------
  const resetButtons = () => {
    setToPrintFormsIds(new Set());
    setIsPrintable(false);
  };

  // ------------- OnClick functions -----------------
  const onExportButtonPressed = () => {
    const { formAnswers, forms } = objects;
    if (Object.keys(formAnswers).length > 0) {
      exportData(forms, formAnswers, objects.questionTypes);
    } else {
      alert('No existen respuestas a exportar con los filtros seleccionados. Intente nuevamente.');
    }
  };

  const onPrintFormsButtonPresed = (printable) => {
    if (printable) {
      setToPrintFormsIds(new Set());
    }
    setIsPrintable(!isPrintable);
  };

  const printFormsHandler = () => {
    window.print();
  };

  const onDeleteFormAnswerButtonClicked = (formAnswerId) => {
    actions.deleteFormAnswer(formAnswerId);
  };

  const onGobackButtonClicked = () => {
    actions.setFormAnswer(null);
    history.push('/forms');
  };

  // ------------- Components -----------------
  const goBackButton = (
    <Button
      startIcon={<ArrowBackIcon />}
      id="exportBtn"
      style={{ marginTop: 10, color: mainColor }}
      onClick={onGobackButtonClicked}
      variant="outlined"
      color="primary"
    >
      Volver a la lista de formularios
    </Button>
  );

  const formResultsOptions = Object.values(objects.forms).map((form) => (
    <MenuItem color="primary" value={form.id} key={`form-option-${form.id}`}>
      {form.name.toLowerCase()}
    </MenuItem>
  ));

  const formResultsSelect = (
    <Typography variant="h3" component="h2">
      <Select
        value={controls?.selectedFormId || []}
        onChange={(select) => onFormIdChange(select.target.value, true)}
        style={{ color: colors.mainColor }}
      >
        {formResultsOptions}
      </Select>
    </Typography>
  );

  const disablePrintFormsButton = controls.isLoading || !(Object.values(objects.formAnswers).length > 0);
  const disablePrint = toPrintFormAnswersIds.size === 0;

  const formQuestions = Object.values(objects.questions).filter(item => item.formId.toString() === controls.selectedFormId && item.questionTypeId === 2)

  const filtersComponent = (
    <Filters
      onChange={onDateFilterChange}
      controls={controls}
      disablePrint={disablePrint}
      disablePrintFormsButton={disablePrintFormsButton}
      isPrintable={isPrintable}
      printFormsHandler={printFormsHandler}
      onExportButtonPressed={onExportButtonPressed}
      onPrintFormsButtonPresed={onPrintFormsButtonPresed}
      onPeriodChange={onPeriodChange}
      onQuestionChange={onQuestionChange}
      formQuestions={formQuestions}
      onAnswerChange={onAnswerChange}
      onClickSearch={onClickSearch}
      onClickClean={onClickClean}
    />
  );

  const formAnswersComponent = !controls.isLoading
    ? (
      <FormAnswers
        classes={classes}
        controls={controls}
        objects={objects}
        isPrintable={isPrintable}
        toPrintFormAnswersIds={toPrintFormAnswersIds}
        setToPrintFormsIds={setToPrintFormsIds}
        selectedFormId={controls.selectedFormId}
        onDeleteFormAnswer={onDeleteFormAnswerButtonClicked}
        createApproval={actions.createApproval}
        getFormAnswersFromApi={actions.getFormAnswersFromApi}
        setFormAnswer={actions.setFormAnswer}
        deleteApproval={actions.deleteApproval}
        openDeleteModal={actions.openDeleteModal}
        closeDeleteModal={actions.closeDeleteModal}
        deleteModalIsOpen={controls.deleteModalIsOpen}
      />
    ) : (
      <Typography color="secondary" component="h2" className={classes.loadingText}>
        Cargando información...
      </Typography>
    );

  const printableSection = (
    <PrintableForms
      printOnly
      toPrintFormAnswersIds={toPrintFormAnswersIds}
      formAnswers={objects.formAnswers}
      questions={objects.questions}
      questionTypes={objects.questionTypes}
    />
  );

  return (
    <Container maxWidth="false" className="no-padding">
      {/* NON-Printable */}
      <Grid item xs={12} sm={12} md={12} xl={12} lg={12} className="no-print" style={{ paddingTop: 10, paddingBottom: 0 }}>
        {goBackButton}
      </Grid>
      <Grid container spacing={3} className={`${classes.container} no-print`} style={{ marginBottom: 8 }}>
        <Grid item xs={12} lg={6} className="formTitle" style={{ paddingBottom: 20 }}>
          {formResultsSelect}
        </Grid>
      </Grid>
      <Card className={`${classes.card} no-print`}>
        <CardContent className={classes.cardContent}>
          <div style={{ flexDirection: 'column' }}>
            {filtersComponent}
          </div>
          {formAnswersComponent}
        </CardContent>
      </Card>

      {/* Printable */}
      {printableSection}
    </Container>
  );
};

const withSaga = connectSaga({ key: 'sagaHome', saga });

const mapDispatchToProps = (dispatch) => (
  { actions: bindActionCreators({ ...formActions }, dispatch) }
);

const withConnect = connect(
  selectState(
    'login.isLogged',
    'app.objects',
    'singleForm.controls',
  ),
  mapDispatchToProps,
  null,
  { context: ReactReduxContext },
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect,
)(withSaga(Form));
