import camelize from 'camelcase-keys-deep';

export default class FormAnswer {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  form() {
    return this.env().Form.findBy('id', this.formId);
  }

  questionAnswers() {
    return this.env().QuestionAnswer.findAllBy('formAnswerId', this.id);
  }

  entity() {
    return this.env().Entity.findBy('userId', this.userId);
  }

  approvals() {
    return this.env().Approval.findAllBy('formAnswerId', this.id);
  }
}
