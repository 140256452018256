/* eslint-disable no-param-reassign */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Material
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Project
import { ReactReduxContext } from 'config/configureStore';
import { actionProps, selectState } from 'utils/reduxActions';
import connectSaga from 'utils/saga';
import saga from './saga';

import { loginActions } from './reducer';
import styles from './styles';
import logo from '../../utils/images/TcitLogo.png';
import { clone, truthty } from 'utils/functions';
import { appActions } from 'commons/reducer';

const Login = props => {
  useEffect(() => {
    const user = localStorage.getItem('user');
    if (truthty(user)) {
      props.actions.setUser(JSON.parse(user));
    } else {
      props.actions.getUser();
    }
  }, [props.actions]);

  useEffect(() => {
    if (!props.loadingUser) {
      if (truthty(props.user)) {
        props.history.push('/forms');
      } else if (process.env.NODE_ENV !== 'production') {
        window.location.href = 'http://localhost:5000/auth/oauth2';
      } else {
        window.location.href = `${window.location.href}auth/oauth2`;
      }
    }
  }, [props.user, props.loadingUser, props.history]);

  return (
    <Grid className={props.classes.container}>
      <Card className={props.classes.card}>
        <Grid className={props.classes.inputs}>
          <img src={logo} className={props.classes.image} alt="logo" />
          <Typography className={props.classes.h1}>Software de Levantamiento terreno</Typography>
          <CircularProgress className={props.classes.loading} />
        </Grid>
      </Card>
    </Grid>
  );
};

const withSaga = connectSaga({ key: 'sagaLogin', saga });

const withConnect = connect(
  selectState(
    'app.loadingUser',
    'login.error',
    'app.user',
  ),
  actionProps(clone(clone.OBJECT, loginActions, appActions)),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect,
)(withSaga(Login));
