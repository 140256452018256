/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { formatQuestionValue, formatStringDate } from '../../../../utils/functions';
import colors from '../../../../utils/colors';
import styles from './styles';
import '../../form.css';

import CustomTooltip from '../../../../commons/components/Tooltip';
import DeleteModal from '../../../../commons/components/DeleteModal';

import useWindowDimensions from '../../../../commons/hooks/useWindowsDimensions';

const useStyles = makeStyles(styles);

const FormAnswers = (props) => {
  const selfClasses = useStyles(styles);
  const { classes, closeDeleteModal, controls, createApproval, isPrintable, deleteApproval, deleteModalIsOpen, toPrintFormAnswersIds, openDeleteModal, objects, onDeleteFormAnswer, setFormAnswer, setToPrintFormsIds } = props;
  const { formAnswers, questionTypes, approvals, entities } = objects;
  const { loadingApproval } = controls;
  const { width } = useWindowDimensions();
  const [approvalsModalIsOpen, setApprovalsModal] = useState(false);
  const [formAnswerId, setFormAnswerId] = useState(null);

  let approvers = [];
  let approved = null;
  const userId = Object.values(JSON.parse(localStorage.getItem('user')))[0].id;
  if (controls.selectedFormAnswer) {
    const approvalsArray = Object.values(approvals).filter((approval) => approval.formAnswerId === controls.selectedFormAnswer.id);
    approvers = Object.values(approvalsArray).reduce((acc, el) => {
      const entity = Object.values(entities).find((ent) => ent.userId === el.userId);
      const approver = {
        name: entity.name,
        approvedAt: el.approvedAt,
      };
      acc.push(approver);
      return acc;
    }, []);
    approved = Object.values(approvals).find((approval) => approval.userId === userId && approval.formAnswerId === controls.selectedFormAnswer.id);
  }

  const formAnswersArray = Object.values(formAnswers);

  const message = formAnswersArray.length > 0
    ? { status: true, msg: 'Formularios sincronizados' }
    : { status: false, msg: 'No existen formularios registrados según la fecha seleccionada.'};

  const handleApprove = () => {
    if (approved) deleteApproval(approved.id);
    else createApproval(userId, controls.selectedFormAnswer.id);
  };

  const handleDeleteFormAnswers = (faId) => {
    setFormAnswerId(faId);
    openDeleteModal();
  };

  const createDeleteFormAnswerButton = (faId) => (
    <CustomTooltip title="Eliminar Formulario">
      <IconButton
        onClick={() => handleDeleteFormAnswers(faId)}
        data-id={faId}
      >
        <DeleteIcon />
      </IconButton>
    </CustomTooltip>
  );

  const handlePrintFormCheckbox = (checked, faId) => {
    if (checked) {
      toPrintFormAnswersIds.add(faId);
    } else {
      toPrintFormAnswersIds.delete(faId);
    }
    setToPrintFormsIds(new Set(toPrintFormAnswersIds));
  };

  const createPrintFormAnswerCheckbox = (faId) => (
    <CustomTooltip title="Imprimir formulario">
      <Checkbox onChange={(event) => handlePrintFormCheckbox(event.target.checked, faId)} />
    </CustomTooltip>
  );

  const noAnswer = (
    <div className="answersContainer">
      <Typography>Sin respuesta</Typography>
      <WarningIcon className="warningIcon" />
    </div>
  );

  const createFormAnswerListXL = (fa) => (
    <List style={{ padding: 0 }}>
      <ListItem
        key={fa.id}
        onClick={() => setFormAnswer(fa)}
        selected={controls.selectedFormAnswer && controls.selectedFormAnswer.id === fa.id}
      >
        <ListItemAvatar>
          <Avatar className={selfClasses.faIdAvatar}>
            {`${fa.id}`}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={fa?.entity()?.name}
          secondary={formatStringDate(fa.date, false, true)}
        />
        <ListItemSecondaryAction>
          {isPrintable
            ? createPrintFormAnswerCheckbox(fa.id)
            : createDeleteFormAnswerButton(fa.id)}
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );

  let formAnswersComponent;
  if (controls.selectedFormAnswer) {
    const questionAnswers = controls.selectedFormAnswer.questionAnswers().sort((a, b) => a.question()?.index - b.question()?.index);
    const answersList = questionAnswers.map((qa) => {
      const question = props.objects.questions[qa.questionId];
      let answer = noAnswer;
      if (qa.value) {
        const questionType = Object.values(questionTypes).find((qT) => qT.id === question.questionTypeId);
        if(questionType.code === 'DOC') {
          answer = <Link href={Object.values(objects.documents).find(d => d.file.includes(qa.value))?.url || '#'}>{qa.value}</Link>
        } else {
          answer = formatQuestionValue(questionType.code, qa.value);
        }
        
      }

      const result = (width <= 600)
        ? (
          <Grid style={{ paddingTop: 2, paddingBottom: 2 }} container>
            <Grid item xs={12}>
              <Typography>
                {`${question.index + 1}. ${question.text}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ paddingLeft: 15, paddingTop: 10, paddingBottom: 10 }}>
                {answer}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container key={question.index} className="answersContainer">
            <Grid item xs={6} style={{ paddingTop: 15, paddingBottom: 15, paddingLeft: 15 }}>
              <Typography color="secondary" className="questionsColor">
                {`${question.index + 1}. ${question.text}`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {answer}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <hr style={{ margin: 0 }} />
            </Grid>
          </Grid>
        );

      return result;
    });

    const defaultIcon = approved ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />;
    const approvalButtonIcon = loadingApproval ? <CircularProgress size={15} /> : defaultIcon;

    const resultList = (width <= 600)
      ? answersList
      : (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography style={{ padding: 20 }} component="h2" className="messageColor">
              Respuestas
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.sideModule}>
            {answersList}
            <Box
              display="flex"
              justifyContent="right"
              style={{ paddingRight: 15, paddingTop: 15, paddingBottom: 10 }}
            >
              <Button
                disabled={loadingApproval}
                endIcon={approvalButtonIcon}
                onClick={() => handleApprove()}
                variant="outlined"
                style={{ color: !approved && colors.grey }}
                color={approved && 'primary'}
              >
                { approved ? 'Aprobado' : 'Aprobar'}
              </Button>
              <Button
                endIcon={<VisibilityIcon />}
                onClick={() => setApprovalsModal(true)}
                variant="outlined"
                style={{ marginLeft: 15 }}
                color="primary"
              >
                {`Ver Aprobadores ( ${approvers.length} Persona/s )`}
              </Button>
            </Box>
          </Grid>
        </Grid>
      );

    formAnswersComponent = resultList;
  }

  const createFormAnswerListXS = (fa) => (
    <Grid style={{ paddingBottom: 2, paddingTop: 2 }} className={selfClasses.root}>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => setFormAnswer(fa)}
        >
          <ListItemAvatar>
            <Avatar className={selfClasses.faIdAvatar}>
              {`${fa?.id}`}
            </Avatar>
          </ListItemAvatar>
          <Typography className={selfClasses.heading}>
            {`${fa?.entity()?.name} - ${formatStringDate(fa?.date, false, true)}`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            {formAnswersComponent}
          </div>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );

  // TODO: Refactor to use lazy rendering or conditional rendering
  const formAnswersList = formAnswersArray.map((fa) => (
    <div key={`${fa.id}-container`}>
      {width <= 600 ? createFormAnswerListXS(fa) : createFormAnswerListXL(fa)}
    </div>
  ));

  const approvalsModal = (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={() => setApprovalsModal(false)}
      aria-labelledby="simple-dialog-title"
      open={approvalsModalIsOpen}
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Nombre</TableCell>
                    <TableCell align="center">Fecha</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {approvers.map((approver) => (
                    <TableRow>
                      <TableCell align="center" style={{ color: 'grey' }}>{approver.name}</TableCell>
                      <TableCell align="center" style={{ color: 'grey' }}>{formatStringDate(approver.approvedAt, false, true)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => setApprovalsModal(false)} variant="outlined">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );

  const deleteModal = (
    <DeleteModal
      toggleForm={closeDeleteModal}
      buttonId="delete-form"
      submit={() => onDeleteFormAnswer(formAnswerId)}
      isOpen={deleteModalIsOpen}
    />
  );

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item sm={6} xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography style={{ paddingTop: 15, paddingBottom: 15 }} component="h2" className="messageColor">
              {message.msg}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 5, paddingBottom: 45 }} className={classes.sideModule}>
              {formAnswersList}
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={6} xs={12}>
        {message.status && formAnswersComponent}
      </Grid>

      {approvalsModal}
      {deleteModal}
    </Grid>
  );
};

export default FormAnswers;
