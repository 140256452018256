import React, { useEffect } from 'react';
import { Container, Card, CardHeader, CardContent, Grid, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import { formatQuestionValue } from '../../../../utils/functions';

const PrintableForms = (props) => {
  const { toPrintFormAnswersIds, formAnswers, printOnly, questions, questionTypes } = props;

  const noAnswer = (
    <div className="answersContainer">
      <Typography style={{ color: 'black' }}>Sin respuesta</Typography>
      <WarningIcon className="warningIcon" />
    </div>
  );

  const formsAnswersToPrint = Object.values(formAnswers).filter((fa) => toPrintFormAnswersIds.has(fa.id));
  const printableForms = formsAnswersToPrint.map((formAnswer) => {
    const formName = formAnswer.form()?.name;
    const answers = formAnswer.questionAnswers().sort((a, b) => a.question()?.index - b.question()?.index);

    const answersList = answers.map((qa) => {
      const question = qa.question();
      let answer = noAnswer;
      if (qa.value) {
        const questionType = Object.values(questionTypes).find((qT) => qT.id === question.questionTypeId);
        answer = formatQuestionValue(questionType.code, qa.value);
      }

      return (
        <Grid style={{ paddingTop: 2, paddingBottom: 2 }} container>
          <Grid item xs={12}>
            <Typography style={{ color: 'black' }}>
              {`${question.index + 1}. ${question.text}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ paddingLeft: 15, paddingTop: 10, paddingBottom: 10, color: 'black' }}>
              {answer}
            </Typography>
          </Grid>
        </Grid>
      );
    });

    // TODO: create a setting variable on DB to read this data from
    const bussinessName = 'Río-Loa';

    const signaturesSection = (
      <Grid container className="signature-section">
        <Grid item xs={6} className="signature-box">
          <Typography>{`Firma ${bussinessName}`}</Typography>
        </Grid>
        <Grid item xs={6} className="signature-box">
          <Typography>Firma Cliente</Typography>
        </Grid>
      </Grid>
    );

    // const a4PaperSize = '1122px';
    const letterPaperSize = '1056px';
    return (
      <Card style={{ height: letterPaperSize }}>
        <CardHeader title={formName} style={{ borderBottom: '1px solid black' }} />
        <CardContent style={{ position: 'relative', paddingLeft: 40, paddingRight: 40, height: letterPaperSize }}>
          <Grid container>
            <Grid item xs={6} style={{ marginBottom: 10 }}>
              <Typography variant="h5" style={{ color: 'black' }}>
                Respuestas
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" style={{ textAlign: 'right', color: 'black' }}>
                {`Formulario Nro: ${formAnswer.id}`}
              </Typography>
            </Grid>
          </Grid>
          {answersList}
          {signaturesSection}
        </CardContent>
      </Card>
    );
  });

  return (
    <Container className={`${printOnly ? 'print-only' : ''}`} style={{ padding: 0 }}>
      {printableForms}
    </Container>
  );
};

export default PrintableForms;
