import camelize from 'camelcase-keys-deep';

export default class Form {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  questions() {
    const questions = this.env().Question.findAllBy('formId', this.id);
    return questions.sort((a, b) => a.index - b.index);
  }

  formAnswers() {
    return this.env().FormAnswer.findAllBy('formId', this.id);
  }
}
