/* eslint-disable no-undef */
/* eslint-disable react/jsx-filename-extension */
// @ts-nocheck
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
// Project
// material
import {
  Snackbar, Grid, Dialog, DialogContent, CircularProgress,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
// components
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import { deleteCookie } from '../../../utils/api';
import saga from '../../saga';
import connectSaga from '../../../utils/saga';
import { ReactReduxContext } from '../../../config/configureStore';
import { actionProps, selectState } from '../../../utils/reduxActions';
import { appActions } from '../../reducer';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Layout = (props) => {
  const {
    children, error, errorMsg, success, successMsg, actions, user, objects,
  } = props;
  const {
    toggleDrawer, toggleError, toggleSuccess, setLoading,
  } = actions;
  const { isLoading } = useSelector((state) => state.app);

  const logOut = () => {
    setLoading();
    localStorage.clear();
    deleteCookie('token');
    if (process.env.NODE_ENV !== 'production') {
      window.location.href = 'http://localhost:5000/logout';
    } else {
      window.location.href = `${window.location.origin}/logout`;
    }
  };

  const loaderModal = (
    <Dialog
      open={isLoading}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );

  return (
    <>
      <NavBar logOut={logOut} toggleDrawer={toggleDrawer} user={user} entities={objects.entities} />
      <div className="content-container">
        <Grid container justify="center">
          <Grid container item md={11} sm={12}>
            {children}
          </Grid>
        </Grid>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={error}
        autoHideDuration={3000}
        onClose={toggleError}
      >
        <Alert onClose={toggleError} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={success}
        autoHideDuration={3000}
        onClose={toggleSuccess}
      >
        <Alert onClose={toggleSuccess} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>
      <Footer />
      {loaderModal}
    </>
  );
};

// ------------------ CONFIG CONTAINER ------------------

const withSaga = connectSaga({ key: 'sagaApp', saga });

const withConnect = connect(
  selectState(
    'app.drawerIsOpen',
    'app.deviceError',
    'app.device',
    'app.errorMsg',
    'app.error',
    'app.successMsg',
    'app.success',
    'app.user',
    'app.objects',
  ),
  actionProps(appActions),
  null,
  { context: ReactReduxContext },
);

export default compose(withRouter, withConnect)(withSaga(Layout));
