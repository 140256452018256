/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';
import moment from 'moment';

// Local constants
const PATH = 'form/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const FORM = {
  SET_CURRENT_FORM_ID: `${PATH}SET_CURRENT_FORM_ID`,
  GET_FORM_ANSWERS_FROM_API: `${PATH}GET_FORM_ANSWERS_FROM_API`,
  GET_FORM_ANSWERS_FROM_API_SUCCESS: `${PATH}GET_FORM_ANSWERS_FROM_API_SUCCESS`,
  DELETE_FORM_ANSWER: `${PATH}DELETE_FORM_ANSWER`,
  DELETE_FORM_ANSWER_SUCCESS: `${PATH}DELETE_FORM_ANSWER_SUCCESS`,
  SET_LOADING: `${PATH}SET_LOADING`,
  CREATE_APPROVAL: `${PATH}CREATE_APPROVAL`,
  CREATE_APPROVAL_SUCCESS: `${PATH}CREATE_APPROVAL_SUCCESS`,
  DELETE_APPROVAL: `${PATH}DELETE_APPROVAL`,
  DELETE_APPROVAL_SUCCESS: `${PATH}DELETE_APPROVAL_SUCCESS`,
  SET_FORM_ANSWER: `${PATH}SET_FORM_ANSWER`,
  OPEN_DELETE_MODAL: `${PATH}OPEN_DELETE_MODAL`,
  CLOSE_DELETE_MODAL: `${PATH}CLOSE_DELETE_MODAL`,
};

// actions
export const formActions = {
  setCurrentFormId: createAction(FORM.SET_CURRENT_FORM_ID, 'id'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  setLoading: createAction(FORM.SET_LOADING, 'isLoading'),
  setFormAnswer: createAction(FORM.SET_FORM_ANSWER, 'formAnswer'),
  openDeleteModal: createAction(FORM.OPEN_DELETE_MODAL),
  closeDeleteModal: createAction(FORM.CLOSE_DELETE_MODAL),
  // // api
  getFormAnswersFromApi: createAction(FORM.GET_FORM_ANSWERS_FROM_API, 'formId'),
  deleteFormAnswer: createAction(FORM.DELETE_FORM_ANSWER, 'id'),
  createApproval: createAction(FORM.CREATE_APPROVAL, 'userId', 'formAnswerId'),
  deleteApproval: createAction(FORM.DELETE_APPROVAL, 'id'),
  // // success
  getFormAnswersFromApiSuccess: createAction(FORM.GET_FORM_ANSWERS_FROM_API_SUCCESS, 'payload'),
};

// const defaultInvalidControls = {
//     name: false,
// };

const initialState = {
  controls: { // Controls defines user editable / manageable content
    startDate: moment(new Date()).format('YYYY-MM-DD'), // From filters component
    endDate: moment(new Date()).format('YYYY-MM-DD'), // from filters component
    currentPeriodNumber: '0', // period number used for the filters
    selectedFormId: null, // url auto / user selectable form id
    selectedFormAnswer: null,
    selectedQuestionId: null,
    selectedAnswerValue: null,
    questionAnswers: [],
    isLoading: false,
    deleteModalIsOpen: false,
    loadingApproval: false,
  },
};

const form = createReducer(initialState, {
  [CHANGE_CONTROLS](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [FORM.SET_LOADING](state, action) {
    state.controls.isLoading = action.payload.isLoading;
  },
  [FORM.SET_CURRENT_FORM_ID](state, action) {
    state.controls.selectedFormId = action.id;
  },
  [FORM.SET_FORM_ANSWER](state, action) {
    state.controls.selectedFormAnswer = action.formAnswer;
  },
  [FORM.DELETE_FORM_ANSWER_SUCCESS](state) {
    state.controls.deleteModalIsOpen = false;
  },
  [FORM.OPEN_DELETE_MODAL](state) {
    state.controls.deleteModalIsOpen = true;
  },
  [FORM.CLOSE_DELETE_MODAL](state) {
    state.controls.deleteModalIsOpen = false;
  },
  [FORM.CREATE_APPROVAL](state) {
    state.controls.loadingApproval = true;
  },
  [FORM.DELETE_APPROVAL](state) {
    state.controls.loadingApproval = true;
  },
  [FORM.CREATE_APPROVAL_SUCCESS](state) {
    state.controls.loadingApproval = false;
  },
  [FORM.DELETE_APPROVAL_SUCCESS](state) {
    state.controls.loadingApproval = false;
  },
});

export default form;
