/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'forms/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_QUESTIONS_FORM = `${PATH}TOGGLE_QUESTIONS_FORM`;
const TOGGLE_EDIT_FORM = `${PATH}TOGGLE_EDIT_FORM`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;
const TOGGLE_QUESTION_ANSWERS = `${PATH}TOGGLE_QUESTION_ANSWERS`;
const OPEN_EDITQUESTION_MODAL = `${PATH}OPEN_EDITQUESTION_MODAL`;
const CLOSE_EDITQUESTION_MODAL = `${PATH}CLOSE_EDITQUESTION_MODAL`;
const TOGGLE_ALTERNATIVE_MODAL = `${PATH}TOGGLE_ALTERNATIVE_MODAL`;

// Global constants for saga
export const FORMS = {
  CREATE_FORM: `${PATH}CREATE_FORM`,
  CREATE_FORM_SUCCESS: `${PATH}CREATE_FORM_SUCCESS`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  DELETE_FORM: `${PATH}DELETE_FORM`,
  DELETE_FORM_SUCCESS: `${PATH}DELETE_FORM_SUCCESS`,
  EDIT_FORM_SUCCESS: `${PATH}EDIT_FORM_SUCCESS`,
  EDIT_FORM: `${PATH}EDIT_FORM`,
  EDIT_QUESTION_SUCCESS: `${PATH}EDIT_QUESTION_SUCCESS`,
  EDIT_QUESTION: `${PATH}EDIT_QUESTION`,
  SAVE_QUESTIONS: `${PATH}SAVE_QUESTIONS`,
  SAVE_QUESTIONS_SUCCESS: `${PATH}SAVE_QUESTIONS_SUCCESS`,
  GET_FORM_ANSWERS_FROM_API: `${PATH}GET_FORM_ANSWERS_FROM_API`,
  GET_FORM_ANSWERS_FROM_API_SUCCESS: `${PATH}GET_FORM_ANSWERS_FROM_API_SUCCESS`,
  DELETE_FORM_ANSWER: `${PATH}DELETE_FORM_ANSWER`,
  DELETE_FORM_ANSWER_SUCCESS: `${PATH}DELETE_FORM_ANSWER_SUCCESS`,
  ADD_ALTERNATIVE_OPTION_FROM_STATE: `${PATH}ADD_ALTERNATIVE_OPTION_FROM_STATE`,
  ADD_ALTERNATIVE_OPTION_FROM_API: `${PATH}ADD_ALTERNATIVE_OPTION_FROM_API`,
  ADD_ALTERNATIVE_OPTION_FROM_API_SUCCESS: `${PATH}ADD_ALTERNATIVE_OPTION_FROM_API_SUCCESS`,
  UPDATE_ALTERNATIVE_OPTION_FROM_API: `${PATH}UPDATE_ALTERNATIVE_OPTION_FROM_API`,
  UPDATE_ALTERNATIVE_OPTION_FROM_API_SUCCESS: `${PATH}UPDATE_ALTERNATIVE_OPTION_FROM_API_SUCCESS`,
  UPDATE_QUESTION: `${PATH}UPDATE_QUESTION`,
  UPDATE_QUESTION_SUCCESS: `${PATH}UPDATE_QUESTION_SUCCESS`,
  UPDATE_MANDATORY_QUESTION: `${PATH}UPDATE_MANDATORY_QUESTION`,
  UPDATE_MANDATORY_QUESTION_SUCCESS: `${PATH}UPDATE_MANDATORY_QUESTION_SUCCESS`,
  UPDATE_FORM: `${PATH}UPDATE_FORM`,
  UPDATE_FORM_SUCCESS: `${PATH}UPDATE_FORM_SUCCESS`,
  OPEN_DELETE_QUESTION_MODAL: `${PATH}OPEN_DELETE_QUESTION_MODAL`,
  CLOSE_DELETE_QUESTION_MODAL: `${PATH}CLOSE_DELETE_QUESTION_MODAL`,
  UPDATE_QUESTION_OPTIONS_INDEX: `${PATH}UPDATE_QUESTION_OPTIONS_INDEX`,
  UPDATE_QUESTION_OPTIONS_INDEX_SUCCESS: `${PATH}UPDATE_QUESTION_OPTIONS_INDEX_SUCCESS`,
};

// actions
export const formsActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  toggleQuestionsForm: createAction(TOGGLE_QUESTIONS_FORM, 'id'),
  toggleEdit: createAction(TOGGLE_EDIT_FORM, 'form'),
  openEditQuestionModal: createAction(OPEN_EDITQUESTION_MODAL, 'question'),
  closeEditQuestionModal: createAction(CLOSE_EDITQUESTION_MODAL),
  toggleQuestionsAnswers: createAction(TOGGLE_QUESTION_ANSWERS, 'id'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  toggleAlternativeModal: createAction(TOGGLE_ALTERNATIVE_MODAL, 'question', 'options'),
  openDeleteQuestionModal: createAction(FORMS.OPEN_DELETE_QUESTION_MODAL),
  closeDeleteQuestionModal: createAction(FORMS.CLOSE_DELETE_QUESTION_MODAL),
  // api
  getFormAnswersFromApi: createAction(FORMS.GET_FORM_ANSWERS_FROM_API, 'formId'),
  deleteFormAnswer: createAction(FORMS.DELETE_FORM_ANSWER, 'id'),
  getStateFromApi: createAction(FORMS.GET_STATE_FROM_API),
  saveQuestions: createAction(FORMS.SAVE_QUESTIONS, 'id', 'controls'),
  createForm: createAction(FORMS.CREATE_FORM, 'controls'),
  deleteForm: createAction(FORMS.DELETE_FORM, 'id'),
  editForm: createAction(FORMS.EDIT_FORM, 'id', 'controls'),
  editQuestion: createAction(FORMS.EDIT_QUESTION, 'id', 'newName', 'index'),
  addAlternativeOptionFromState: createAction(FORMS.ADD_ALTERNATIVE_OPTION_FROM_STATE, 'index', 'value'),
  addAlternativeOptionFromApi: createAction(FORMS.ADD_ALTERNATIVE_OPTION_FROM_API, 'id', 'value'),
  updateAlternativeOption: createAction(FORMS.UPDATE_ALTERNATIVE_OPTION_FROM_API, 'id', 'disabled', 'index'),
  updateQuestion: createAction(FORMS.UPDATE_QUESTION, 'id', 'disabled', 'index'),
  updateMandatoryQuestion: createAction(FORMS.UPDATE_MANDATORY_QUESTION, 'id', 'mandatory', 'index'),
  updateForm: createAction(FORMS.UPDATE_FORM, 'id', 'disabled'),
  updateQuestionOptionsIndex: createAction(FORMS.UPDATE_QUESTION_OPTIONS_INDEX, 'options'),
  // success
  getFormAnswersFromApiSuccess: createAction(FORMS.GET_FORM_ANSWERS_FROM_API_SUCCESS, 'payload'),
  getStateFromApiSuccess: createAction(FORMS.GET_STATE_FROM_API_SUCCESS, 'payload'),
  saveQuestionsSuccess: createAction(FORMS.SAVE_QUESTIONS_SUCCESS, 'payload'),
  createFormSuccess: createAction(FORMS.CREATE_FORM_SUCCESS, 'payload'),
  deleteFormSuccess: createAction(FORMS.DELETE_FORM_SUCCESS, 'payload'),
  editFormSuccess: createAction(FORMS.EDIT_FORM_SUCCESS, 'payload'),
  editQuestionSuccess: createAction(FORMS.EDIT_QUESTION_SUCCESS, 'payload'),
  addAlternativeOptionFromApiSuccess: createAction(FORMS.ADD_ALTERNATIVE_OPTION_FROM_API_SUCCESS, 'payload'),
  updateAlternativeOptionSuccess: createAction(FORMS.UPDATE_ALTERNATIVE_OPTION_FROM_API_SUCCESS, 'payload'),
  updateQuestionSuccess: createAction(FORMS.UPDATE_QUESTION_SUCCESS, 'payload'),
  updateMandatoryQuestionSuccess: createAction(FORMS.UPDATE_MANDATORY_QUESTION_SUCCESS, 'payload'),
  updateFormSuccess: createAction(FORMS.UPDATE_FORM_SUCCESS, 'payload'),
  updateQuestionOptionsIndexSuccess: createAction(FORMS.UPDATE_QUESTION_OPTIONS_INDEX_SUCCESS, 'payload'),
};

const defaultInvalidControls = {
  name: false,
  text: false,
};

const initialState = {
  formControls: {
    name: '',
    questionName: '',
    optionName: '',
    questionTypeId: 1,
    questions: [],
    questionOptions: [],
    toDeleteQuestionIds: [],
    formAnswers: [],
    questionAnswers: [],
    isLoading: false,
  },
  questionsFormOpen: false,
  invalidControls: { ...defaultInvalidControls },
  formIsOpen: false,
  deleteIsOpen: false,
  modalEditQuestionIsOpen: false,
  formsAnswersIsOpen: false,
  toDeleteId: '',
  toEditId: '',
  formId: '',
  edit: false,
  error: false,
  currentQuestion: '',
  alternativeModalIsOpen: false,
  options: [],
  questionDeleteModalIsOpen: false,
};

const forms = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls.name = '';
    state.formControls.taxNumber = '';
    state.invalidControls = { ...defaultInvalidControls };
    state.toDeleteQuestionIds = [];
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls,
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    };
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [TOGGLE_EDIT_FORM](state, action) {
    state.formIsOpen = true;
    state.toDeleteId = '';
    state.toEditId = action.form.id;
    state.formControls.name = action.form.name;
    state.edit = true;
  },
  [TOGGLE_QUESTIONS_FORM](state, action) {
    state.questionsFormOpen = true;
    state.formId = action.id;
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    };
  },
  [TOGGLE_QUESTION_ANSWERS](state, action) {
    state.formsAnswersIsOpen = true;
    state.formId = action.id;
  },
  [OPEN_EDITQUESTION_MODAL](state, action) {
    state.modalEditQuestionIsOpen = true;
    state.currentQuestion = action.question;
  },
  [CLOSE_EDITQUESTION_MODAL](state, action) {
    state.modalEditQuestionIsOpen = false;
    state.currentQuestion = '';
  },
  [TOGGLE_ALTERNATIVE_MODAL](state, action) {
    state.alternativeModalIsOpen = !state.alternativeModalIsOpen;
    state.currentQuestion = action.question ? action.question : '';
    state.options = action.options ? action.options : [];
  },
  // api
  [FORMS.SAVE_QUESTIONS_SUCCESS](state, action) {
    state.questionsFormOpen = false;
    state.formId = null;
    state.formControls.toDeleteQuestionIds = [];
    state.formControls.questions = [];
    state.formControls.questionOptions = [];
  },
  [FORMS.CREATE_FORM_SUCCESS](state, action) {
    state.formIsOpen = false;
    state.formControls.name = '';
    state.formControls.taxNumber = '';
    state.questionsFormOpen = true;
    state.formId = Object.values(action.payload.objects.forms)[0].id;
    state.invalidControls = { ...defaultInvalidControls };
  },
  [FORMS.DELETE_FORM_SUCCESS](state, action) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
  },
  [FORMS.EDIT_FORM_SUCCESS](state, action) {
    state.formIsOpen = false;
    state.toDeleteId = '';
    state.toEditId = '';
    state.edit = false;
  },
  [FORMS.EDIT_QUESTION](state) {
    state.formControls.isLoading = true;
  },
  [FORMS.EDIT_QUESTION_SUCCESS](state, action) {
    // TODO: Move logic to editQuestion function
    if (action.payload.objects) {
      const { id, text } = action.payload.objects.questions[state.currentQuestion.id];
      const questionIdx = state.formControls.questions.findIndex((question) => question.id === id);
      state.formControls.questions[questionIdx].text = text;
    } else {
      const { index, newName } = action.payload.item;
      const questionIdx = state.formControls.questions.findIndex((question) => question.index === index);
      state.formControls.questions[questionIdx].text = newName;
    }
    state.formControls.isLoading = false;
    state.modalEditQuestionIsOpen = false;
  },
  [FORMS.ADD_ALTERNATIVE_OPTION_FROM_STATE](state, action) {
    state.formControls.questions[action.index].questionOptions.push(action.value);
    state.options.push({ value: action.value });
    state.formControls.isLoading = false;
  },
  [FORMS.ADD_ALTERNATIVE_OPTION_FROM_API_SUCCESS](state, action) {
    state.options.push({ value: action.payload.value });
    state.formControls.isLoading = false;
  },
  [FORMS.UPDATE_ALTERNATIVE_OPTION_FROM_API_SUCCESS](state, action) {
    const { index, objects } = action.payload;
    const newOption = Object.values(objects.questionOptions)[0];
    state.options[index] = newOption;
  },
  [FORMS.UPDATE_QUESTION_SUCCESS](state, action) {
    const { index, objects } = action.payload;
    const newQuestion = Object.values(objects.questions)[0];
    state.formControls.questions[index] = newQuestion;
  },
  [FORMS.OPEN_DELETE_QUESTION_MODAL](state) {
    state.questionDeleteModalIsOpen = true;
  },
  [FORMS.CLOSE_DELETE_QUESTION_MODAL](state) {
    state.questionDeleteModalIsOpen = false;
  },
  [FORMS.UPDATE_MANDATORY_QUESTION_SUCCESS](state, action) {
    const { index, objects } = action.payload;
    const newQuestion = Object.values(objects.questions)[0];
    state.formControls.questions[index] = newQuestion;
  },
});

export default forms;
