// @ts-nocheck
import React, { useState, useEffect } from 'react';

import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import {
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Link,
} from '@material-ui/core';
import produce from 'immer';
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';
import { formatNumber, formatStringDate } from '../../../../utils/functions';

const FormAnswerList = (props) => {
  const [formAnswer, setFormAnswer] = useState(null);
  const {
    actions, classes, isOpen, objects,
  } = props;
  const defaultState = {
    startDate: moment(new Date()).format('YYYY-MM-DD'),
    endDate: moment(new Date()).format('YYYY-MM-DD'),
  };

  const [state, setState] = useState(defaultState);
  const form = objects.forms[props.formId];
  const formAnswers = form && form.formAnswers() ? form.formAnswers() : [];
  const [filteredFormAnswers, setFilteredFormAnswers] = useState([]);

  useEffect(() => {
    if (props.formId) {
      actions.getFormAnswersFromApi(props.formId);
    }
  }, [props.formId]);

  useEffect(() => {
    const filteredFormAnswers = formAnswers.filter(
      fa => fa.date >= state.startDate && fa.date.substring(0, 10) <= state.endDate,
    );
    setFilteredFormAnswers(filteredFormAnswers);
  }, [state]);

  function onChangeState(event) {
    event.persist();
    setState(
      produce(draft => {
        draft[event.target.id] = event.target.value;
      }),
    );
  }

  function onClickExport() {
    const formAnswers = Object.values(objects.formAnswers);
    const object = formAnswers.map((fa) => {
      const questionAnswers = fa.questionAnswers()
        .sort((a, b) => a.question()?.index - b.question()?.index)
        .reduce((prev, current) => {
          const question = current.question();
          const numberType = Object.values(objects.questionTypes)
            .find(t => t.code === 'Numérico');
          let value = '';
          if (current.value || current.value === 0) {
            value = Number(current.value);
          }
          return {
            ...prev,
            [question.text]: question.questionTypeId === numberType?.id ? value : current.value,
          };
        }, {});

      return {
        Usuario: fa.entity()?.name,
        Fecha: formatStringDate(fa.date),
        Latitud: fa.latitude,
        Longitud: fa.longitude,
        Numero: fa.id,
        ...questionAnswers,
        Aprobadores: fa.approvals().map((approval) => approval.user()?.name || 'SIN NOMBRE').join(', '),
      };
    });

    const wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(object);
    // TODO: check filter headers
    ws['!autofilter'] = { ref: 'A1:Z1' };

    XLSX.utils.book_append_sheet(wb, ws, 'Respuestas');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `respuestas.xlsx`);
  }

  // TODO: refactor to avoid usage of ids
  function getAnswerValue(qa, question) {
    if (question.questionTypeId === 6) { // ! <<<<<<<<<<<<<<<
      if (objects.documents[qa.documentId]) {
        return (
          <Link href={objects.documents[qa.documentId].url}>
            {qa.value}
          </Link>
        );
      }
      return 'No existe un documento asociado.';
  } else if (question.questionTypeId === 3) { // ! <<<<<<<<<<<<<<<
      return formatNumber(qa.value);
    }
    return qa.value;
  }

  return (
    <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={() => actions.toggleForm('formsAnswersIsOpen')}>
      <DialogTitle>{`Respuestas formulario ${form && form.name}`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={3} xs={12}>
            <TextField
              type="date"
              id="startDate"
              label="Desde"
              fullWidth
              value={state.startDate}
              onChange={(e) => onChangeState(e)}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <TextField
              type="date"
              id="endDate"
              label="Hasta"
              fullWidth
              value={state.endDate}
              onChange={(e) => onChangeState(e)}
            />
          </Grid>
          <Grid item sm={6} xs={12} alignItems="flex-end" justify="flex-end" direction="row">
            <Button
              startIcon={<GetAppIcon />}
              id="exportBtn"
              style={{ float: 'right', marginTop: 10 }}
              onClick={() => onClickExport()}
            >
              Exportar respuestas
            </Button>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography color="secondary" component="h2">
                  Formularios llenados
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.sideModule}>
                <List>
                  {filteredFormAnswers.map((fa) => (
                    // <FormAnswerRow formAnswer={fa} setFormAnswer={setFormAnswer} />
                    <ListItem
                      key={fa.id}
                      onClick={() => setFormAnswer(fa)}
                      selected={formAnswer && formAnswer.id === fa.id}
                    >
                      <ListItemAvatar>
                        <Avatar>{/* <AssignmentIcon /> */}</Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={fa?.entity()?.name} secondary={formatStringDate(fa.date)} />
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => actions.deleteFormAnswer(fa.id)} data-id={fa.id}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} xs={12}>
            {formAnswer && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography color="secondary" component="h2">
                    Respuestas
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.sideModule}>
                  {formAnswer.questionAnswers()
                    .sort((a, b) => a.question()?.index - b.question()?.index)
                    .map((qa) => {
                      const question = objects.questions[qa.questionId];
                      return (
                        <Grid container spacing={2} key={question.index}>
                          <Grid item xs={6}>
                            <Typography color="secondary">{`${question.index + 1}. ${question.text}`}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>
                              {getAnswerValue(qa, question)}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => (actions.toggleForm('formsAnswersIsOpen'), setState(defaultState))}
          color="primary"
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormAnswerList;
