import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
// styles
import {
  withStyles, Container, Grid, Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
// import { clone, truthty, falsy } from 'utils/functions';
import produce from 'immer';
// utils
import { clone, falsy } from '../../utils/functions';
// components
import DeleteModal from '../../commons/components/DeleteModal';
import FormAnswerList from './components/FormAnswerList';
import QuestionsForm from './components/QuestionsForm';
import ModalForm from './components/ModalForm';
import ItemList from './components/ItemList';
import Filters from './components/Filters';
import CustomTooltip from '../../commons/components/Tooltip';
// Project
import connectSaga from '../../utils/saga';
import { formsActions } from './reducer';
import { ReactReduxContext } from '../../config/configureStore';
import { selectState, actionProps } from '../../utils/reduxActions';
import saga from './saga';

import styles from './styles';

const useStyles = makeStyles(styles);

const Employees = (props) => {
  const [filter, setFilter] = useState({ name: '' });
  const [formsList, setFormsList] = useState([]);
  const employeeClasses = useStyles();

  const {
    actions,
    objects,
    classes,
    currentQuestion,
    deleteIsOpen,
    edit,
    formId,
    formIsOpen,
    formsAnswersIsOpen,
    formControls,
    invalidControls,
    modalEditQuestionIsOpen,
    alternativeModalIsOpen,
    toEditId,
    toDeleteId,
    options,
    questionDeleteModalIsOpen,
  } = props;

  const { questionsFormOpen } = useSelector((state) => state.form);

  useEffect(() => {
    actions.getStateFromApi();
  }, [actions]);

  useEffect(() => {
    const filteredForms = (
      Object.values(objects.forms)
        .filter((item) => item.name.toLowerCase().includes(filter.name.toLowerCase()))
    );
    setFormsList(filteredForms);
  }, [objects.forms, filter]);

  useEffect(() => {
    if (formId && objects.forms[formId]) {
      const questions = objects.forms[formId].questions();
      actions.changeControls({ questions });
    }
  }, [formId]);

  function onFilterChange(event) {
    event.persist();
    const id = event.target.type ? event.target.id : event.currentTarget.id;
    setFilter(
      produce((draft) => {
        draft[id] = event.target.value;
      }),
    );
  }

  function changeControls(event) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    actions.changeControls({ [event.target.id]: value }, { [event.target.id]: false });
  }

  function submit() {
    if (falsy(formControls.name)) {
      actions.setInvalidControls({
        name: falsy(formControls.name),
      });
    } else if (edit) {
      actions.editForm(toEditId, formControls);
    } else {
      actions.createForm(formControls);
    }
  }

  function saveQuestions() {
    actions.saveQuestions(formId, formControls);
  }

  return (
    <Container>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} className={classes.containerTitle} >
          <Typography variant='h3' component='h2' className={`${classes.marginTop} ${classes.title}`}>
            FORMULARIOS
          </Typography>
          <Filters classes={employeeClasses} filter={filter} onFilterChange={onFilterChange} />
        </Grid>

        <ItemList formsList={formsList} actions={actions} classes={employeeClasses} />

        <ModalForm
          invalidControls={invalidControls}
          changeControls={changeControls}
          onChange={actions.changeControls}
          controls={formControls}
          actions={actions}
          isOpen={formIsOpen}
          submit={submit}
          edit={edit}
        />

        {/* questions forms */}
        {questionsFormOpen && (
          <QuestionsForm
            invalidControls={invalidControls}
            changeControls={changeControls}
            controls={formControls}
            isOpen={props.questionsFormOpen}
            submit={saveQuestions}
            actions={actions}
            objects={objects}
            edit={edit}
            formId={formId}
            editQuestionIsOpen={modalEditQuestionIsOpen}
            alternativeModalIsOpen={alternativeModalIsOpen}
            currentQuestion={currentQuestion}
            options={options}
            questionDeleteModalIsOpen={questionDeleteModalIsOpen}
          />
        )}

        <FormAnswerList
          actions={actions}
          objects={objects}
          controls={formControls}
          isOpen={formsAnswersIsOpen}
          formId={formId}
          classes={employeeClasses}
        />

        <DeleteModal
          toggleForm={() => actions.toggleForm('deleteIsOpen')}
          buttonId="delete-form"
          submit={() => actions.deleteForm(toDeleteId)}
          isOpen={deleteIsOpen}
        />

        <CustomTooltip title="Nuevo Formulario">
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fab}
            onClick={() => actions.toggleForm('formIsOpen')}
          >
            <AddIcon />
          </Fab>
        </CustomTooltip>
      </Grid>
    </Container>
  );
};

const withSaga = connectSaga({ key: 'sagaForms', saga });

const withConnect = connect(
  selectState(
    'form.questionDeleteModalIsOpen',
    'form.modalEditQuestionIsOpen',
    'form.alternativeModalIsOpen',
    'form.formsAnswersIsOpen',
    'form.questionsFormOpen',
    'form.invalidControls',
    'form.currentQuestion',
    'form.formControls',
    'form.deleteIsOpen',
    'form.toDeleteId',
    'form.formIsOpen',
    'form.toEditId',
    'form.options',
    'form.formId',
    'form.edit',
    'app.objects',
  ),
  actionProps(clone(clone.OBJECT, formsActions)),
  null,
  {
    context: ReactReduxContext,
  },
);

export default compose(withStyles(styles), withRouter, withConnect)(withSaga(Employees));
