/* eslint-disable import/no-unresolved */
//! Warning: Copied from Pakit, needs refactor before using
/* eslint-disable react/jsx-filename-extension */
// @ts-nocheck
import React, { Fragment, useState } from 'react';
// material
import {
  AppBar,
  Box,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
// icons
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import IconButton from '@material-ui/core/IconButton';
import BarChartIcon from '@material-ui/icons/BarChart';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useHistory } from 'react-router';
import colors from 'utils/colors';
import { withStyles } from '@material-ui/core/styles';
import { truthty } from 'utils/functions';
import useWindowDimensions from '../../hooks/useWindowsDimensions';
// styles
import Logo from '../../assets/logo_grabit.png';
import WhiteLogo from '../../assets/grabit_blanco-03.svg';
import './navbar.css';

const NavBar = (props) => {
  const { width } = useWindowDimensions();
  const { withoutLogout, logOut, user, entities } = props;

  const loginUser = Object.values(user)[0];

  const isMobile = width <= 959;

  const history = useHistory();

  const [showMenu, setShowMenu] = useState(false);

  const list = (
    <List>
      <ListItem button key="Inbox" onClick={() => history.push('/forms')}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="Formularios" />
      </ListItem>
      <ListItem button key="Reporte" onClick={() => history.push('/report')}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Reporte" />
      </ListItem>
      {!withoutLogout && (
        <ListItem onClick={logOut} button key="Sesion">
          <ListItemIcon>
            <CloseIcon />
          </ListItemIcon>
          <ListItemText primary="Cerrar sesion" />
        </ListItem>
      )}
    </List>
  );

  const WhiteTextTypography = withStyles({
    root: {
      color: colors.white,
    },
  })(Typography);

  // TODO: Move anchor to redux
  const menu = (
    <Fragment key={showMenu}>
      <Drawer anchor="left" open={showMenu} onClose={() => setShowMenu(false)}>
        {list}
      </Drawer>
    </Fragment>
  );

  const appLogo = <img style={{ height: 50, width: 50, paddingRight: 5 }} src={isMobile ? Logo : WhiteLogo} alt="logo" />;

  const pageTitle = (
    <Typography variant="h6">
      GRABIT
    </Typography>
  );

  const menuButton = (
    <IconButton onClick={() => setShowMenu(true)} aria-label="menu" style={{ marginRight: 40, color: isMobile ? colors.grey : colors.white }}>
      <MenuIcon />
    </IconButton>
  );

  return (
    <AppBar position="static" className="no-print">
      <Grid
        container
        alignItems="center"
        style={{ height: 70 }}
      >
        <Grid item xs={3} sm={2} md={2} lg={2} xl={1}>
          <Box display="flex" justifyContent="flex-end">
            {menuButton}
            {appLogo}
          </Box>
        </Grid>
        <Grid item xs={5} sm={4} md={2} lg={1} xl={1}>
          {pageTitle}
        </Grid>
        <Grid item xs={4} sm={6} md={6} lg={8} xl={9} />
        {!isMobile && truthty(user) && truthty(entities) && (
          <Grid item lg={1}>
            <WhiteTextTypography>
              {Object.values(entities).find((entity) => entity.id === loginUser.id).name}
            </WhiteTextTypography>
            <WhiteTextTypography>
              {loginUser.email}
            </WhiteTextTypography>
          </Grid>
        )}
      </Grid>
      {menu}
    </AppBar>
  );
};

export default NavBar;
