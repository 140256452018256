import { blueGrey } from '@material-ui/core/colors';
import colors from '../../utils/colors';

const { mainColor } = colors;

const styles = (theme) => ({
  root: {
    flex: 1,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(10),
    right: theme.spacing(6),
  },
  blue: {
    color: theme.palette.getContrastText(blueGrey[500]),
    backgroundColor: blueGrey[500],
    fontFamily: [
      'Roboto',
    ],
  },
  marginTop: {
    marginTop: '24px',
  },
  sideModule: {
    height: '70vh',
    overflow: 'auto',
  },
  card: {
    minHeight: '70vh',
    marginBottom: 50,
    flexGrow: 1,
  },
  cardContent: {
    minHeight: '70vh',
  },
  container: {
    paddingTop: 20,
  },
  loadingText: {
    padding: 20,
    color: mainColor,
  },
  mainTitle: {
    whiteSpace: 'unset',
  },
});

export default styles;
