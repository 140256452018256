// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
// material
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Divider,
  Typography,
  IconButton,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// utils
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// eslint-disable-next-line import/no-extraneous-dependencies
import CustomTooltip from '../../../../commons/components/Tooltip/index';
import colors from '../../../../utils/colors';
import { falsy, truthty } from '../../../../utils/functions';

const useStyles = makeStyles(() => ({
  input: {
    color: colors.grey,
  },
  value: {
    color: 'black',
    fontWeight: 'bold',
  },
  valueDisabled: {
    color: 'grey',
    fontWeight: 'bold',
  },
  optionItem: {
    height: 50,
    border: `1px dashed ${colors.mainColor}`,
    borderRadius: 25,
    marginBottom: 5,
  },
  alternativesTitle: {
    color: colors.mainColor,
    fontSize: 15,
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 15,
  },
}));

const AlternativeModal = (props) => {
  const {
    question, isOpen, actions, invalidControls, options,
  } = props;
  // @ts-ignore
  const { isLoading, questions } = useSelector((state) => state.form.formControls);
  const classes = useStyles();
  const [questionName, setQuestionName] = useState('');
  const [alternativeName, setAlternativeName] = useState('');
  const [handleAlternativeErrors, setHandleAlternativeErrors] = useState({ error: false, msg: '' });
  const [alternativeOptions, setAlternativeOptions] = useState();

  useEffect(() => {
    if (falsy(options) || isOpen === false) return;

    // @ts-ignore
    setAlternativeOptions([...options].sort((a, b) => a.index - b.index));
  }, [options]);

  useEffect(() => {
    if (!question.text) return;

    setQuestionName(question.text);
  }, [question.text]);

  const handleQuestionName = () => {
    if (questionName === question.text) {
      return;
    }

    if (falsy(questionName)) {
      actions.setInvalidControls({ text: true });
    } else {
      actions.setInvalidControls({ text: false });
      actions.editQuestion(question.id, questionName, question.index);
    }
  };

  const handleAlternativeName = () => {
    if (falsy(alternativeName)) {
      setHandleAlternativeErrors({ error: true, msg: 'No puede estar vacio' });
      return;
    }

    const option = options.find((option) => option.value === alternativeName);
    if (option) {
      setHandleAlternativeErrors({ error: true, msg: 'Ya existe una opcion con ese nombre' });
      return;
    }

    setHandleAlternativeErrors({ error: false, msg: '' });
    setAlternativeName('');
    if (!question.id) {
      const indexQuestion = questions.findIndex((q) => q.index === question.index);
      actions.addAlternativeOptionFromState(indexQuestion, alternativeName);
    } else {
      actions.addAlternativeOptionFromApi(question.id, alternativeName);
    }
  };

  const handleClose = () => {
    setHandleAlternativeErrors({ error: false, msg: '' });
    setAlternativeName('');
    actions.setInvalidControls({ text: false });
    actions.toggleAlternativeModal();
  };

  const handleUpdateAlternativeOption = (id, disabled) => {
    const optionIndex = alternativeOptions.findIndex((o) => o.id === id);
    actions.updateAlternativeOption(id, disabled, optionIndex);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // ----------------------- Components ----------------------------

  const questionNameField = (
    <TextField
      value={questionName}
      onChange={(e) => setQuestionName(e.target.value)}
      fullWidth
      label="Nombre"
      error={invalidControls.text}
      helperText={invalidControls.text && 'Debe ingresar un nombre'}
      inputProps={{ className: classes.input }}
    />
  );

  const questionNameButton = (
    <Box display="flex" justifyContent="center" alignItems={invalidControls.text ? 'center' : 'flex-end'} style={{ height: '100%' }}>
      <Button
        color="primary"
        variant="contained"
        disabled={isLoading}
        startIcon={isLoading && <CircularProgress size={15} />}
        onClick={() => handleQuestionName()}
      >
        Editar
      </Button>
    </Box>
  );

  const newAlternativeField = (
    <TextField
      value={alternativeName}
      onChange={(e) => setAlternativeName(e.target.value)}
      fullWidth
      label="Nombre alternativa"
      inputProps={{ className: classes.input }}
      error={handleAlternativeErrors.error}
      helperText={handleAlternativeErrors.error && handleAlternativeErrors.msg}
    />
  );

  const newAlternativeButton = (
    <Box display="flex" justifyContent="center" alignItems={handleAlternativeErrors.error ? 'center' : 'flex-end'} style={{ height: '100%' }}>
      <Button
        color="primary"
        variant="contained"
        disabled={isLoading}
        startIcon={isLoading && <CircularProgress size={15} />}
        onClick={() => handleAlternativeName()}
      >
        Agregar
      </Button>
    </Box>
  );

  const dragOptionHandler = (result) => {
    const { source, destination } = result;
    if (!destination || (source.index === destination.index)) return;
    setAlternativeOptions((prevList) => {
      const orderList = reorder(prevList, source.index, destination.index);
      actions.updateQuestionOptionsIndex(orderList);
      return orderList;
    });
  };

  const getFullOptionComponent = (option, index) => (
    <Draggable key={option.id} draggableId={option.id.toString()} index={index}>
      {(draggableProvided) => (
        <Grid
          container
          alignItems="center"
          {...draggableProvided.draggableProps}
          ref={draggableProvided.innerRef}
          {...draggableProvided.dragHandleProps}
          className={classes.optionItem}
        >
          <Grid item xs={2}>
            <Typography align="center">{index + 1}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography align="center" className={option.disabled ? classes.valueDisabled : classes.value}>{option.value}</Typography>
          </Grid>
          <Grid item xs={2}>
            <CustomTooltip title={`${option.disabled ? 'Mostrar' : 'Ocultar'} alternativa`}>
              <IconButton
                className="icon-hover"
                onClick={() => handleUpdateAlternativeOption(option.id, !option.disabled)}
                style={{ padding: 0 }}
                disabled={isLoading}
              >
                {option.disabled ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </CustomTooltip>
          </Grid>
        </Grid>
      )}
    </Draggable>
  );

  const getSimpleOptionComponent = (option, index) => (
    <Grid
      container
      alignItems="center"
      className={classes.optionItem}
    >
      <Grid item xs={2}>
        <Typography align="center">{index + 1}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography align="center" className={option.disabled ? classes.valueDisabled : classes.value}>{option.value}</Typography>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );

  const alternativesSection = (truthty(alternativeOptions) && (
    <DragDropContext
      onDragEnd={(result) => dragOptionHandler(result)}
    >
      <Droppable droppableId="options">
        {(droppableProvided) => (
          <div
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            style={{ paddingTop: 20 }}
          >
            <Typography className={classes.alternativesTitle} align="center">ALTERNATIVAS</Typography>
            {/* If the option exist in the db uses the full component, if not, uses the simple instead without drag'n'drop and without hiding */}
            {alternativeOptions.map((option, index) => (option.id
              ? getFullOptionComponent(option, index)
              : getSimpleOptionComponent(option, index)
            ))}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  ));

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={() => handleClose()}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      <DialogTitle>Editar pregunta</DialogTitle>

      <DialogContent>
        <Grid container style={{ marginBottom: 20 }}>
          <Grid item sm={8} xs={12}>
            {questionNameField}
          </Grid>
          <Grid item sm={4}>
            {questionNameButton}
          </Grid>
        </Grid>

        <Divider />

        <Grid container style={{ marginBottom: 20, marginTop: 20 }}>
          <Grid item sm={5} xs={12}>
            <Box display="flex" alignItems="center" style={{ height: '100%' }}>
              <Typography style={{ color: 'grey', fontSize: '16px' }}>
                Nueva alternativa
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: 10 }}>
          <Grid item sm={8} xs={12}>
            {newAlternativeField}
          </Grid>
          <Grid item sm={4}>
            {newAlternativeButton}
          </Grid>
        </Grid>

        <Divider />

        {alternativesSection}
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={() => handleClose()} variant="outlined">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlternativeModal;
