import {
  takeLatest,
  spawn,
  // put,
} from 'redux-saga/effects';

import apiRequest from '../../utils/api';
import { LOG_OUT } from '../reducer';
// import { LOG_OUT, SEARCH_PRINTER, SEARCH_PRINTER_FAIL, SEARCH_PRINTER_SUCCESS, PRINT, appActions } from 'commons/reducer';

// const VENDOR_ID = 2655;

function* logout() {
  yield takeLatest(LOG_OUT, function* () {
    yield apiRequest('/logout', { method: 'get' });
  });
}

export default function* root() {
  yield spawn(logout);
}
