import React from 'react';
import {
  Button,
  MenuItem,
  TextField,
  InputLabel,
  Select,
  FormControl,
  Grid,
  makeStyles,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import PrintIcon from '@material-ui/icons/Print';
import SearchIcon from "@material-ui/icons/Search";
import CleanIcon from '../../../../commons/components/CleanIcon';
import colors from '../../../../utils/colors';

const useStyles = makeStyles(() => ({
  input: {
    color: colors.grey,
  },
}));

const Filters = (props) => {
  const { controls, isPrintable, disablePrint, disablePrintFormsButton, onChange, onExportButtonPressed, printFormsHandler, onPrintFormsButtonPresed, onPeriodChange, onQuestionChange, formQuestions, onAnswerChange, onClickSearch, onClickClean } = props;
  const classes = useStyles();

  const currentPeriodSelector = (
    <FormControl style={{ width: '100%' }}>
      <InputLabel
        shrink
        id="demo-simple-select-placeholder-label-label"
        style={{ color: colors.grey }}
      >
        Periodo
      </InputLabel>
      <Select
        value={controls.currentPeriodNumber}
        onChange={onPeriodChange}
        style={{ color: colors.grey }}
      >
        <MenuItem value="0">Hoy</MenuItem>
        <MenuItem value="1">Ayer</MenuItem>
        <MenuItem value="2">Últimos 7 días</MenuItem>
        <MenuItem value="3">Últimos 14 días</MenuItem>
        <MenuItem value="4">Esta semana</MenuItem>
        <MenuItem value="5">Semana pasada</MenuItem>
        <MenuItem value="6">Este mes</MenuItem>
        <MenuItem value="7">El mes pasado</MenuItem>
        <MenuItem value="-1">Personalizado</MenuItem>
      </Select>
    </FormControl>
  );

  const startDateInput = (
    <TextField
      type="date"
      id="startDate"
      label="Desde"
      value={controls.startDate}
      onChange={(date) => onChange(date, 'start')}
      fullWidth
      inputProps={{ className: classes.input }}
    />
  );

  const endDateInput = (
    <TextField
      type="date"
      id="endDate"
      label="Hasta"
      value={controls.endDate}
      onChange={(date) => onChange(date, 'end')}
      fullWidth
      inputProps={{ className: classes.input }}
    />
  );

  const questionSelector = (
    <FormControl style={{ width: '100%' }}>
      <InputLabel
        shrink
        id="question"
        style={{ color: colors.grey }}
      >
        Campo
      </InputLabel>
      <Select
        value={controls.selectedQuestionId}
        onChange={onQuestionChange}
        style={{ color: colors.grey }}
      >
        {
          formQuestions.map((item) => {
            return (
              <MenuItem value={item.id}>{item.text}</MenuItem>
            )
          })
        }
      </Select>
    </FormControl>
  );

  const answerSelector = (
    <FormControl style={{ width: "100%" }} disabled={!!!controls.selectedQuestionId}>
      <InputLabel shrink id="answer" style={{ color: colors.grey }}>
        Valor
      </InputLabel>
      <Select
        value={controls.selectedAnswerValue}
        onChange={onAnswerChange}
        style={{ color: colors.grey }}
      >
        {controls.questionAnswers.map((item) => {
          return <MenuItem value={item.value}>{item.value}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );

  const exportAnswersButton = (
    <Button
      startIcon={<GetAppIcon className="messageColor" />}
      id="exportBtn"
      style={{ marginTop: 10 }}
      onClick={onExportButtonPressed}
      variant="outlined"
      color="primary"
      fullWidth
    >
      Exportar respuestas
    </Button>
  );

  const printFormsButton = (
    <Button
      startIcon={<PrintIcon className={isPrintable ? '' : 'messageColor'} />}
      id="exportBtn"
      style={{ marginTop: 10 }}
      onClick={printFormsHandler}
      variant={isPrintable ? 'contained' : 'outlined'}
      color="primary"
      disabled={disablePrint}
      fullWidth
    >
      Imprimir
    </Button>
  );

  const selectPrintableFormsButton = (
    <Button
      startIcon={<PrintIcon className={disablePrintFormsButton ? '' : 'messageColor'} />}
      id="printBtn"
      style={{ marginTop: 10 }}
      onClick={() => onPrintFormsButtonPresed(isPrintable)}
      variant="outlined"
      color="primary"
      disabled={disablePrintFormsButton}
      fullWidth
    >
      {isPrintable ? 'Cancelar' : 'Imprimir formularios'}
    </Button>
  );

  const cleanFiltersButton = (
    <Button
      startIcon={<CleanIcon />}
      id="cleanBtn"
      style={{ marginTop: 10 }}
      variant="outlined"
      color="primary"
      fullWidth
      onClick={() => onClickClean()}
    >
      Limpiar
    </Button>
  );

  const searchButton = (
    <Button
      startIcon={<SearchIcon />}
      id="searchBtn"
      style={{ marginTop: 10 }}
      variant="outlined"
      color="primary"
      fullWidth
      onClick={() => onClickSearch()}
    >
      Buscar
    </Button>
  );

  return (
    <Grid container spacing={1}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          {currentPeriodSelector}
        </Grid>
        <Grid item xs={12} sm={4}>
          {startDateInput}
        </Grid>
        <Grid item xs={12} sm={4}>
          {endDateInput}
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ marginTop: 10 }}>
        {formQuestions.length > 0 ? (
          <>
            <Grid item xs={12} sm={4}>
              {questionSelector}
            </Grid>
            <Grid item xs={12} sm={4}>
              {answerSelector}
            </Grid>
          </>
        ): (<Grid item xs={false} sm={8}></Grid>)}
        <Grid item xs={12} sm={2}>
          {searchButton}
        </Grid>
        <Grid item xs={12} sm={2}>
          {cleanFiltersButton}
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        {selectPrintableFormsButton}
      </Grid>
      <Grid item xs={12} sm={6}>
        {isPrintable ? printFormsButton : exportAnswersButton}
      </Grid>
    </Grid>
  );
};

export default Filters;
