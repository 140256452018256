/* eslint-disable no-shadow */
/* eslint-disable react/jsx-filename-extension */
// @ts-nocheck
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// material
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  makeStyles,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
// Icons
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
// Components
import ButtonWithLoader from '../../../../commons/containers/ButtonWithLoader';
import DeleteModal from '../../../../commons/components/DeleteModal';
import ModalEditQuestion from '../ModalEditQuestion';
import AlternativeModal from '../AlternativeModal';
import CustomTooltip from '../../../../commons/components/Tooltip';

import colors from '../../../../utils/colors';
import '../../forms.css';

const useStyles = makeStyles(() => ({
  input: {
    color: colors.grey,
  },
}));

const QuestionsForm = (props) => {
  const { objects } = useSelector((state) => state.app);
  const { questionTypes, questionOptions } = objects;
  const questionTypesArray = Object.values(objects.questionTypes);
  const classes = useStyles();

  const {
    actions, controls, changeControls, invalidControls, isOpen, formId, submit, editQuestionIsOpen, currentQuestion, alternativeModalIsOpen, options, questionDeleteModalIsOpen,
  } = props;

  const questionType = questionTypesArray.find((question) => question.id === controls.questionTypeId);
  const disabledButton = (questionType.code === 'ALT' && controls.questionOptions.length < 2) || !controls.questionName;
  const [questionIndex, setQuestionIndex] = useState(null);

  function addQuestion() {
    const index = controls.questions[controls.questions.length - 1] ? controls.questions[controls.questions.length - 1].index + 1 : 0
    actions.changeControls({
      questions: [
        ...controls.questions,
        {
          text: controls.questionName,
          questionTypeId: controls.questionTypeId,
          index,
          questionOptions: controls.questionOptions,
        },
      ],
      questionName: '',
    });
  }

  function addOption() {
    actions.changeControls({
      questionOptions: [...controls.questionOptions, controls.optionName],
      optionName: '',
    });
  }

  function deleteOption(index) {
    actions.changeControls({
      questionOptions: controls.questionOptions.filter((_, i) => i !== index),
    });
  }

  function deleteQuestion(index) {
    const questionId = controls.questions[index].id;
    if (questionId) {
      actions.changeControls({
        toDeleteQuestionIds: [...controls.toDeleteQuestionIds, questionId],
      });
    }
    actions.changeControls({
      questions: controls.questions.filter((_, i) => i !== index),
    });
    actions.closeDeleteQuestionModal();
  }

  const handleDeleteQuestion = (index) => {
    setQuestionIndex(index);
    actions.openDeleteQuestionModal();
  };

  function swapArray(previous, next) {
    actions.changeControls({
      questions: Object.assign([], controls.questions, {
        [previous]: { ...controls.questions[next], index: previous },
        [next]: { ...controls.questions[previous], index: next },
      }),
    });
  }

  const handleDisabledQuestion = (id, disabled) => {
    const questionIndex = controls.questions.findIndex((q) => q.id === id);
    actions.updateQuestion(id, disabled, questionIndex);
  };

  const handleMandatoryQuestion = (id, mandatory) => {
    const questionIndex = controls.questions.findIndex((q) => q.id === id);
    actions.updateMandatoryQuestion(id, mandatory, questionIndex);
  };

  const questionTypesSelect = (
    <FormControl fullWidth>
      <InputLabel id="product-label">Tipo de campo</InputLabel>
      <Select
        labelId="product-label"
        fullWidth
        value={controls.questionTypeId}
        onChange={(e) => actions.changeControls({ questionTypeId: e.target.value })}
        style={{ color: colors.grey }}
      >
        {questionTypesArray.map((qT) => (
          <MenuItem key={`questionType-filter-option-${qT.id}`} id="productId" value={qT.id}>
            {qT.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const questionNameInput = (
    <TextField
      value={controls.questionName}
      onChange={changeControls}
      fullWidth
      id="questionName"
      label="Nombre"
      error={invalidControls.name}
      helperText={invalidControls.name && 'Debe ingresar un nombre'}
      onKeyDown={(e) => { if (e.key === 'Enter' && !disabledButton) addQuestion(); }}
      inputProps={{ className: classes.input }}
    />
  );

  const addQuestionButton = (
    <CustomTooltip title="Agregar Campo">
      <IconButton
        disabled={disabledButton}
        color="primary"
        style={{ marginTop: 10 }}
        onClick={() => addQuestion()}
        className="icon-hover"
      >
        <SaveIcon />
      </IconButton>
    </CustomTooltip>
  );

  // Alternative section
  let alternativeContainer;
  if (questionType.code === 'ALT') {
    const optionNameInput = (
      <TextField
        value={controls.optionName}
        onChange={changeControls}
        fullWidth
        id="optionName"
        label="Añada una nueva alternativa"
        onKeyDown={(e) => { if (e.key === 'Enter' && controls.optionName !== '') addOption(); }}
      />
    );

    const addOptionButton = (
      <CustomTooltip title="Agregar Alternativa">
        <IconButton
          disabled={!controls.optionName}
          color="primary"
          style={{ marginTop: 10 }}
          onClick={() => addOption()}
          className="icon-hover"
        >
          <AddCircleIcon />
        </IconButton>
      </CustomTooltip>
    );

    const optionsList = (
      <List>
        {controls.questionOptions.map((item, index) => (
          <ListItem style={{ paddingLeft: 0 }}>
            <ListItemText primary={`${(index + 1)}.- ${item}`} />
            <ListItemSecondaryAction>
              <CustomTooltip title="Eliminar Alternativa">
                <IconButton onClick={() => deleteOption(index)} className="icon-hover iconDeleteOption">
                  <DeleteIcon />
                </IconButton>
              </CustomTooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );

    alternativeContainer = (
      <Grid item sm={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item sm={10} xs={10}>
            <Typography variant="h5">Alternativas</Typography>
          </Grid>
          <Grid item sm={10} xs={12}>
            {optionNameInput}
          </Grid>
          <Grid item sm={2} xs={2}>
            {addOptionButton}
          </Grid>
          <Grid item sm={12} xs={12}>
            {optionsList}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const leftSection = (
    <Grid item sm={6} xs={12}>
      <Grid container spacing={2} style={{ position: 'fixed', width: '50%' }}>
        <Grid item sm={10} xs={10}>
          <Typography variant="h5">Nuevo campo</Typography>
        </Grid>

        <Grid item sm={5} xs={12}>
          {questionTypesSelect}
        </Grid>
        <Grid item sm={5} xs={12}>
          {questionNameInput}
        </Grid>
        <Grid item sm={2} xs={2}>
          {addQuestionButton}
        </Grid>

        {alternativeContainer}
      </Grid>
    </Grid>
  );

  const fieldList = (
    <List>
      {controls.questions.map((item, index) => {
        const questionType = questionTypes[item.questionTypeId].code;
        let secondaryLabel = questionTypes[item.questionTypeId].name;
        let optionsFormatted;

        if (questionType === 'ALT') {
          if (item.questionOptions) {
            // When options are in local state but NOT in backend yet
            optionsFormatted = item.questionOptions.reduce((acc, el) => {
              const option = { value: el };
              acc.push(option);
              return acc;
            }, []);
          } else {
            // When options are saved in backend
            optionsFormatted = Object.values(questionOptions).filter((option) => option.questionId === item.id);
          }
          secondaryLabel = `Alternativas (${optionsFormatted.length})`;
        }

        if (item.mandatory) {
          secondaryLabel += ' (pregunta obligatoria)';
        }

        return (
          <ListItem>
            <Grid container>
              <Grid item xs={1} className="centerVertical">
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: item.disabled ? colors.grey : colors.mainColor }}>{index + 1}</Avatar>
                </ListItemAvatar>
              </Grid>
              <Grid item xs={8}>
                <ListItemText
                  primary={item.text}
                  secondary={<Typography style={{ fontWeight: '500', marginTop: 4 }}>{secondaryLabel}</Typography>}
                />
              </Grid>
              <Grid item xs={3}>
                <ListItemSecondaryAction>
                  {index > 0 && (
                    <CustomTooltip title="Subir Campo">
                      <IconButton onClick={() => swapArray(index - 1, index)} className="icon-hover">
                        <ArrowUpwardIcon />
                      </IconButton>
                    </CustomTooltip>
                  )}
                  {index < controls.questions.length - 1 && (
                    <CustomTooltip title="Bajar Campo">
                      <IconButton onClick={() => swapArray(index, index + 1)} className="icon-hover">
                        <ArrowDownwardIcon />
                      </IconButton>
                    </CustomTooltip>
                  )}
                  {item.id && (
                    <CustomTooltip title={`${item.disabled ? 'Mostrar' : 'Ocultar'} pregunta`}>
                      <IconButton onClick={() => handleDisabledQuestion(item.id, !item.disabled)} className="icon-hover">
                        {item.disabled ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </CustomTooltip>
                  )}
                  {item.id && (
                    <CustomTooltip title={`${item.mandatory ? 'Quitar obligación' : 'Hacer pregunta obligatoria'}`}>
                      <IconButton onClick={() => handleMandatoryQuestion(item.id, !item.mandatory)} className="icon-hover">
                        {item.mandatory ? <LockIcon /> : <LockOpenIcon />}
                      </IconButton>
                    </CustomTooltip>
                  )}
                  {questionType === 'ALT' ? (
                    <CustomTooltip title="Editar Campo">
                      <IconButton onClick={() => actions.toggleAlternativeModal(item, optionsFormatted)} className="icon-hover">
                        <EditIcon />
                      </IconButton>
                    </CustomTooltip>
                  ) : (
                    <CustomTooltip title="Editar Campo">
                      <IconButton onClick={() => actions.openEditQuestionModal(item)} className="icon-hover">
                        <EditIcon />
                      </IconButton>
                    </CustomTooltip>
                  )}
                  <CustomTooltip title="Eliminar Campo">
                    <IconButton onClick={() => handleDeleteQuestion(index)} className="icon-hover">
                      <DeleteIcon />
                    </IconButton>
                  </CustomTooltip>
                </ListItemSecondaryAction>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
    </List>
  );

  const rightSection = (
    <Grid item sm={6} xs={12} className="rightSection">
      <Grid container spacing={2}>
        <Grid item sm={10} xs={10}>
          <Typography variant="h5">Campos</Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          {fieldList}
        </Grid>
      </Grid>
    </Grid>
  );

  const dialogTitle = (
    <DialogTitle>
      {formId && objects.forms[formId].name}
      <Divider />
    </DialogTitle>
  );

  const dialogActions = (
    <DialogActions>
      <Button variant="outlined" onClick={() => actions.toggleForm('questionsFormOpen')} color="primary">
        Cancelar
      </Button>
      <ButtonWithLoader onClick={submit} autoFocus buttonId="save-questions">
        Guardar
      </ButtonWithLoader>
    </DialogActions>
  );

  const modalEditQuestion = (
    <ModalEditQuestion
      actions={actions}
      invalidControls={invalidControls}
      isOpen={editQuestionIsOpen}
      currentQuestion={currentQuestion}
    />
  );

  const alternativeModal = (
    <AlternativeModal
      question={currentQuestion}
      options={options}
      isOpen={alternativeModalIsOpen}
      actions={actions}
      invalidControls={invalidControls}
    />
  );

  // TODO: Check if Dialog should be used instead of a container
  return (
    <Dialog
      fullScreen
      onClose={() => actions.toggleForm('questionsFormOpen')}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      {dialogTitle}
      <DialogContent>
        <Grid container spacing={2}>
          {leftSection}
          {rightSection}
        </Grid>
      </DialogContent>
      {dialogActions}

      {modalEditQuestion}
      {alternativeModal}

      <DeleteModal
        toggleForm={actions.closeDeleteQuestionModal}
        buttonId="delete-form"
        submit={() => deleteQuestion(questionIndex)}
        isOpen={questionDeleteModalIsOpen}
      />
    </Dialog>
  );
};

export default QuestionsForm;
