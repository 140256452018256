/* eslint-disable react/jsx-filename-extension */
// @ts-nocheck
import React from 'react';
// material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';
import ButtonWithLoader from '../../../../commons/containers/ButtonWithLoader';
import colors from '../../../../utils/colors';

const useStyles = makeStyles(() => ({
  input: {
    color: colors.grey,
  },
}));

const ModalForm = (props) => {
  const {
    isOpen, edit, controls, changeControls, invalidControls, submit,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      onClose={() => props.actions.toggleForm('formIsOpen')}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      <DialogTitle>{`${edit ? 'Editar' : 'Crear'} formulario`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <TextField
              value={controls.name}
              onChange={changeControls}
              fullWidth
              id="name"
              label="Nombre"
              error={invalidControls.name}
              helperText={invalidControls.name && 'Debe ingresar un nombre'}
              onKeyDown={(e) => { if (e.key === 'Enter') submit(); }}
              inputProps={{ className: classes.input }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.actions.toggleForm('formIsOpen')} color="primary" variant="outlined">
          Cancelar
        </Button>
        <ButtonWithLoader onClick={submit} autoFocus buttonId="create-edit-form">
          {edit ? 'Editar' : 'Agregar'}
        </ButtonWithLoader>
      </DialogActions>
    </Dialog>
  );
};

export default ModalForm;
