// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import { takeLatest, spawn, put } from 'redux-saga/effects';

import apiRequest, {
  apiSuccess, get, post, update,
} from 'utils/api';
import controlledCall from 'utils/services/controlledSaga';
import { appActions } from 'commons/reducer';
import { FORMS, formsActions } from '../reducer';
// import { truthty } from 'utils/functions';

function* getStateFromApi() {
  yield takeLatest(FORMS.GET_STATE_FROM_API, function* (action) {
    yield controlledCall(get, 'api/v1/forms', {}, formsActions.getStateFromApiSuccess);
  });
}

function* getFormAnswersFromApi() {
  yield takeLatest(FORMS.GET_FORM_ANSWERS_FROM_API, function* (action) {
    yield controlledCall(get, `api/v1/form_answers/${action.formId}`, {}, formsActions.getFormAnswersFromApiSuccess);
  });
}

function* createForm() {
  yield takeLatest(FORMS.CREATE_FORM, function* (action) {
    yield put(appActions.setLoader('create-edit-form', true));

    const body = action.controls;
    yield controlledCall(post, 'api/v1/forms', body, formsActions.createFormSuccess);

    yield put(appActions.setLoader('create-edit-form', false));
  });
}

function* editForm() {
  yield takeLatest(FORMS.EDIT_FORM, function* (action) {
    yield put(appActions.setLoader('create-edit-form', true));

    const body = action.controls;
    yield controlledCall(update, `api/v1/forms/${action.id}`, body, formsActions.editFormSuccess);

    yield put(appActions.setLoader('create-edit-form', false));
  });
}

function* editQuestion() {
  yield takeLatest(FORMS.EDIT_QUESTION, function* (action) {
    const { id, newName, index } = action;
    if (id) {
      yield controlledCall(
        update,
        `api/v1/form_answers/question/${id}/editName`,
        { newName },
        formsActions.editQuestionSuccess,
      );
    } else {
      yield put(apiSuccess(FORMS.EDIT_QUESTION_SUCCESS, { item: { newName, index }, type: 'forms' }));
    }
  });
}

function* deleteForm() {
  yield takeLatest(FORMS.DELETE_FORM, function* (action) {
    yield put(appActions.setLoader('delete-form', true));
    const response = yield apiRequest(`api/v1/forms/${action.id}`, { method: 'delete' });
    yield put(apiSuccess(FORMS.DELETE_FORM_SUCCESS, { item: response, type: 'forms' }));

    yield put(appActions.setLoader('delete-form', false));
  });
}

function* saveQuestions() {
  yield takeLatest(FORMS.SAVE_QUESTIONS, function* (action) {
    yield put(appActions.setLoader('save-questions', true));

    const body = action.controls;
    yield controlledCall(
      post,
      `api/v1/forms/${action.id}/questions`,
      body,
      formsActions.saveQuestionsSuccess,
    );

    yield put(appActions.setLoader('save-questions', false));
  });
}

function* deleteFormAnswer() {
  yield takeLatest(FORMS.DELETE_FORM_ANSWER, function* (action) {
    // yield put(appActions.setLoader('delete-form', true));
    const response = yield apiRequest(`api/v1/form_answers/${action.id}`, { method: 'delete' });
    yield put(
      apiSuccess(FORMS.DELETE_FORM_ANSWER_SUCCESS, { item: response, type: 'formAnswers' }),
    );
    // yield put(appActions.setLoader('delete-form', false));
  });
}

function* addAlternativeOption() {
  yield takeLatest(FORMS.ADD_ALTERNATIVE_OPTION_FROM_API, function* (action) {
    const { id, value } = action;
    const response = yield post('api/v1/question_options/', { id, value });
    yield put(apiSuccess(FORMS.ADD_ALTERNATIVE_OPTION_FROM_API_SUCCESS, { ...response, type: 'formAnswers', value }));
  });
}

function* updateAlternativeOption() {
  yield takeLatest(FORMS.UPDATE_ALTERNATIVE_OPTION_FROM_API, function* (action) {
    const { id, disabled, index } = action;
    const response = yield update(`api/v1/question_options/${id}`, { disabled });
    yield put(apiSuccess(FORMS.UPDATE_ALTERNATIVE_OPTION_FROM_API_SUCCESS, { ...response, type: 'formAnswers', index }));
  });
}

function* updateQuestion() {
  yield takeLatest(FORMS.UPDATE_QUESTION, function* (action) {
    const { id, disabled, index } = action;
    const response = yield update(`api/v1/questions/${id}`, { disabled });
    yield put(apiSuccess(FORMS.UPDATE_QUESTION_SUCCESS, { ...response, type: 'formAnswers', index }));
  });
}

function* updateMandatoryQuestion() {
  yield takeLatest(FORMS.UPDATE_MANDATORY_QUESTION, function* (action) {
    const { id, mandatory, index } = action;
    const response = yield update(`api/v1/questions/mandatory/${id}`, { mandatory });
    yield put(apiSuccess(FORMS.UPDATE_MANDATORY_QUESTION_SUCCESS, { ...response, type: 'formAnswers', index }));
  });
}

function* updateForm() {
  yield takeLatest(FORMS.UPDATE_FORM, function* (action) {
    const { id, disabled } = action;
    const response = yield update(`api/v1/forms/${id}/disabled`, { disabled });
    yield put(apiSuccess(FORMS.UPDATE_FORM_SUCCESS, { ...response, type: 'formAnswers' }));
  });
}

function* updateQuestionOptionsIndex() {
  yield takeLatest(FORMS.UPDATE_QUESTION_OPTIONS_INDEX, function* (action) {
    const { options } = action;
    const response = yield post('api/v1/question_options/updateIndex', { options });
    yield put(apiSuccess(FORMS.UPDATE_QUESTION_OPTIONS_INDEX_SUCCESS, { ...response, type: 'formAnswers' }));
  });
}

export default function* saga() {
  yield spawn(getStateFromApi);
  yield spawn(getFormAnswersFromApi);
  yield spawn(saveQuestions);
  yield spawn(deleteForm);
  yield spawn(createForm);
  yield spawn(editForm);
  yield spawn(editQuestion);
  yield spawn(deleteFormAnswer);
  yield spawn(addAlternativeOption);
  yield spawn(updateAlternativeOption);
  yield spawn(updateQuestion);
  yield spawn(updateForm);
  yield spawn(updateMandatoryQuestion);
  yield spawn(updateQuestionOptionsIndex);
}
