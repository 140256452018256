import React from 'react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
// import { Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
// import CardHeader from '@material-ui/core/CardHeader';
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

const Filters = props => (
  <Grid item xs={12} style={{ marginTop: 10 }}>
    <Card className={props.classes.root}>
      {/* <CardHeader title="Filtro" /> */}
      <CardContent>
        <Grid container spacing={3} className={props.classes.container}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id='name'
              label='Filtrar por nombre'
              value={props.filter.name}
              onChange={props.onFilterChange}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);

export default Filters;
