/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
// material
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';
// utils
import { useSelector } from 'react-redux';
import colors from '../../../../utils/colors';
import { falsy } from '../../../../utils/functions';

const useStyles = makeStyles(() => ({
  input: {
    color: colors.grey,
  },
}));

const ModalEditQuestion = (props) => {
  const {
    currentQuestion, isOpen, actions, invalidControls,
  } = props;
  const { isLoading } = useSelector((state) => state.form.formControls);
  const { text, id, index } = currentQuestion;
  const classes = useStyles();
  const [name, setName] = useState('');

  useEffect(() => {
    if (!text) return;

    setName(text);
  }, [text]);

  const submit = () => {
    if (name === text) {
      actions.closeEditQuestionModal();
      return;
    }

    if (falsy(name)) {
      actions.setInvalidControls({ text: true });
    } else {
      actions.setInvalidControls({ text: false });
      actions.editQuestion(id, name, index);
    }
  };

  return (
    <Dialog
      fullWidth
      onClose={actions.closeEditQuestionModal}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      <DialogTitle>Editar pregunta</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              label="Nombre"
              error={invalidControls.text}
              helperText={invalidControls.text && 'Debe ingresar un nombre'}
              onKeyDown={(e) => { if (e.key === 'Enter') submit(); }}
              inputProps={{ className: classes.input }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={actions.closeEditQuestionModal} variant="outlined">
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={submit}
          variant="contained"
          disabled={isLoading}
          startIcon={isLoading && <CircularProgress size={15} />}
        >
          Editar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditQuestion;
