import colors from "utils/colors";

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  faIdAvatar: {
    backgroundColor: colors.mainColor,
    width: theme.spacing(5),
    height: theme.spacing(5),
    fontSize: 12,
    fontWeight: 600,
  },
});

export default styles;
