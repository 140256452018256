// @ts-nocheck
/* eslint-disable import/no-unresolved */
import React from 'react';
import { withRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { appActions } from 'commons/reducer';
import store from "../index";
import { actionProps, selectState } from 'utils/reduxActions';
import { ReactReduxContext } from 'config/configureStore';
import connectSaga from 'utils/saga';
import Paths from 'utils/paths';
import PrivateRoute from 'utils/hooks/PrivateRoute';
import saga from '../commons/saga';
// containers
import Login from 'screens/Login';
import Home from 'screens/Home';
import Form from 'screens/Form';
import Report from 'screens/Report';

// styles
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import Forms from 'screens/Forms';
import colors from 'utils/colors';
import { Provider } from 'react-redux';

const { mainColor, grey } = colors;

const theme = createTheme({
  palette: {
    primary: {
      main: mainColor,
    },
    secondary: {
      main: teal[700],
    },
  }
});

theme.typography.fontFamily = {
  useNextVariants: true,
  fontFamily: [
    'Roboto'
  ]
};
theme.typography.body1 = {
  fontFamily: [
    'Roboto'
  ],
  color: grey
}
theme.typography.body2 = {
  fontFamily: [
    'Roboto'
  ],
  color: grey
}
theme.typography.h3 = {
  fontFamily: [
    'Roboto'
  ],
  color: grey,
  fontSize: '40px'
};
theme.typography.h5 = {
  fontFamily: [
    'Roboto'
  ],
  color: grey,
  fontSize: '20px'
};


const App = (props) => {
  return (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PrivateRoute exact path={Paths.HOME} component={Home}/>
      <PrivateRoute exact path={Paths.FORMS} component={Forms}/>
      <PrivateRoute exact path={Paths.FORM} component={Form}/>
      <PrivateRoute exact path={Paths.REPORT} component={Report}/>
      {/* Maintainers */}
      <Route exact path="/" component={Login}/>
    </Provider>
  </ThemeProvider>
)};

// ------------------ CONFIG CONTAINER ------------------

const withSaga = connectSaga({ key: 'sagaApp', saga });

const withConnect = connect(
  selectState(
    'app.user',
  ),
  actionProps(appActions),
  null,
  { context: ReactReduxContext },
);

export default compose(
  withRouter,
  withConnect,
)(withSaga(App));
