/* eslint-disable import/no-extraneous-dependencies */
// @ts-nocheck
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import {
  takeLatest, spawn, put, select,
} from 'redux-saga/effects';
import moment from 'moment-timezone';

import apiRequest, { apiSuccess, get, post } from 'utils/api';
// import apiRequest, { apiSuccess, get, post, update, destroy } from 'utils/api';
import controlledCall from 'utils/services/controlledSaga';
import { FORM, formActions } from '../reducer';

function* getFormAnswersFromApi() {
  yield takeLatest(FORM.GET_FORM_ANSWERS_FROM_API, function* (action) {
    yield put(
      apiSuccess(FORM.SET_LOADING, { isLoading: true }),
    );

    const state = yield select();

    const { startDate, endDate, selectedQuestionId, selectedAnswerValue } = state.singleForm.controls;
    const apiRoute = `api/v1/form_answers/${action.formId}`;
    const queryParams = `fromDate=${startDate}&endDate=${endDate}&timezone=${moment.tz.guess()}&questionId=${selectedQuestionId}&answerValue=${selectedAnswerValue}`;

    yield controlledCall(
      get,
      apiRoute,
      queryParams,
      formActions.getFormAnswersFromApiSuccess,
    );

    yield put(
      apiSuccess(FORM.SET_LOADING, { isLoading: false }),
    );
  });
}

function* deleteFormAnswer() {
  yield takeLatest(FORM.DELETE_FORM_ANSWER, function* (action) {
    // yield put(appActions.setLoader('delete-form', true));
    const response = yield apiRequest(`api/v1/form_answers/${action.id}`, { method: 'delete' });
    yield put(
      apiSuccess(FORM.DELETE_FORM_ANSWER_SUCCESS, { item: response, type: 'formAnswers' }),
    );

    // yield put(appActions.setLoader('delete-form', false));
  });
}

function* createApproval() {
  yield takeLatest(FORM.CREATE_APPROVAL, function* (action) {
    const { userId, formAnswerId } = action;
    const state = yield select();

    const {
      startDate: fromDate,
      endDate,
      selectedQuestionId: questionId,
      selectedAnswerValue: answerValue,
    } = state.singleForm.controls;

    const response = yield post('api/v1/approvals/', {
      userId,
      formAnswerId,
      fromDate,
      endDate,
      timezone: moment.tz.guess(),
      questionId,
      answerValue,
    });
    yield put(apiSuccess(FORM.CREATE_APPROVAL_SUCCESS, { ...response, type: 'formAnswers' }));
  });
}

function* deleteApproval() {
  yield takeLatest(FORM.DELETE_APPROVAL, function* (action) {
    const response = yield apiRequest(`api/v1/approvals/${action.id}`, { method: 'delete' });
    yield put(apiSuccess(FORM.DELETE_APPROVAL_SUCCESS, { item: response, type: 'approvals' }));
  });
}

export default function* saga() {
  yield spawn(getFormAnswersFromApi);
  yield spawn(deleteFormAnswer);
  yield spawn(createApproval);
  yield spawn(deleteApproval);
}
