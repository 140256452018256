/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createReducer, createAction } from 'utils/reducer';
import { LOGIN } from 'screens/Login/reducer';
import { normalizeObj, removeManyKeysByFilter } from 'utils/functions';
import { FORMS } from 'screens/Forms/reducer';
import { FORM } from 'screens/Form/reducer';

// GLOBAL
export const GET_STATE_FROM_API = 'app/GET_API_FROM_STATE';
export const GET_STATE_FROM_API_SUCCESS = 'app/GET_API_FROM_STATE_SUCCESS';
export const LOG_OUT = 'app/LOG_OUT';
export const TOGGLE_DRAWER = 'app/TOGGLE_DRAWER';
export const TOGGLE_ERROR = 'app/TOGGLE_ERROR';
export const TOGGLE_SUCCESS = 'app/TOGGLE_SUCCESS';
export const SET_ERROR = 'app/SET_ERROR';
export const SET_SUCCESS = 'app/SET_SUCCESS';
export const SET_LOADER = 'app/SET_LOADER';
export const PRINT = 'app/PRINT';
export const SET_LOADING = 'app/SET_LOADING';

export const appActions = {
  // api fetch
  logOut: createAction(LOG_OUT),
  setUser: createAction(LOGIN.GET_USER_SUCCESS, 'payload'),
  // others
  toggleError: createAction(TOGGLE_ERROR),
  toggleSuccess: createAction(TOGGLE_SUCCESS),
  toggleDrawer: createAction(TOGGLE_DRAWER),
  setError: createAction(SET_ERROR, 'msg'),
  setSuccess: createAction(SET_SUCCESS, 'msg'),
  setLoader: createAction(SET_LOADER, 'id', 'state'),
  setLoading: createAction(SET_LOADING),
};

const initialState = {
  drawerIsOpen: false,
  loadingUser: true,
  errorMsg: '',
  error: false,
  successMsg: '',
  success: false,
  isLoading: false,
  objects: {
    entities: {},
    forms: {},
    formAnswers: {},
    questions: {},
    questionTypes: {},
    questionOptions: {},
    approvals: {},
  },
  user: {},
  loaders: {},
  device: {},
};

const mergeObjects = (state, action) => {
  const newObjects = {};
  state.objects.formAnswers = {}; // FormAnswers needs to be reset. (so the date filters can  work).
  state.objects.questionAnswers = {}; // QuestionAnswers needs to be reset. (to avoid duplicates).
  state.objects.approvals = {}; // approvals needs to be reset.
  Object.keys({ ...state.objects, ...action.payload.objects }).forEach((key) => {
    newObjects[key] = {
      ...state.objects[key],
      ...(action.payload.objects[key] || {}),
    };
  });
  state.objects = newObjects;
};

const deleteObject = (state, action) => {
  state.objects[action.payload.type] = normalizeObj(
    Object.values(state.objects[action.payload.type]).filter((item) => item.id !== action.payload.item.id),
  );
};

const deleteObjects = (state, action) => {
  Object.keys(action.payload.toDeleteIds).forEach((key) => {
    state.objects[key] = removeManyKeysByFilter(state.objects[key], action.payload.toDeleteIds[key]);
  });
};

const app = createReducer(initialState, {
  // success
  [FORMS.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [FORMS.CREATE_FORM_SUCCESS]: mergeObjects,
  [FORMS.EDIT_FORM_SUCCESS]: mergeObjects,
  [FORMS.SAVE_QUESTIONS_SUCCESS](state, action) {
    deleteObjects(state, action);
    mergeObjects(state, action);
  },
  [FORMS.DELETE_FORM_SUCCESS]: deleteObject,
  [FORM.DELETE_FORM_ANSWER_SUCCESS]: deleteObject,
  [FORM.GET_FORM_ANSWERS_FROM_API_SUCCESS]: mergeObjects,
  [FORM.CREATE_APPROVAL_SUCCESS]: mergeObjects,
  [FORM.DELETE_APPROVAL_SUCCESS]: deleteObject,
  [FORMS.ADD_ALTERNATIVE_OPTION_FROM_API_SUCCESS]: mergeObjects,
  [FORMS.UPDATE_ALTERNATIVE_OPTION_FROM_API_SUCCESS]: mergeObjects,
  [FORMS.UPDATE_QUESTION_SUCCESS]: mergeObjects,
  [FORMS.UPDATE_MANDATORY_QUESTION_SUCCESS]: mergeObjects,
  [FORMS.UPDATE_FORM_SUCCESS]: mergeObjects,
  [FORMS.UPDATE_QUESTION_OPTIONS_INDEX_SUCCESS](state, action) {
    state.objects.questionOptions = {};
    mergeObjects(state, action);
  },
  // others
  [LOGIN.GET_USER_SUCCESS](state, action) {
    state.user = action.payload;
    state.loadingUser = false;
  },
  [LOGIN.GET_USER](state) {
    state.loadingUser = true;
  },
  [LOGIN.GET_USER_FAIL](state) {
    state.user = {};
    state.loadingUser = false;
  },
  [LOG_OUT](state) {
    state.isLoading = !state.isLoading;
    state.user = {};
  },
  [TOGGLE_DRAWER](state) {
    state.drawerIsOpen = !state.drawerIsOpen;
  },
  [TOGGLE_ERROR](state) {
    state.error = false;
    state.errorMsg = !state.errorMsg;
  },
  [SET_ERROR](state, action) {
    state.error = true;
    state.errorMsg = action.msg;
  },
  [TOGGLE_SUCCESS](state) {
    state.success = false;
    state.successMsg = !state.successMsg;
  },
  [SET_SUCCESS](state, action) {
    state.success = true;
    state.successMsg = action.msg;
  },
  [SET_LOADER](state, action) {
    state.loaders[action.id] = action.state;
  },
  [SET_LOADING](state) {
    state.isLoading = !state.isLoading;
  },
});

export default app;
