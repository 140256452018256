import React from 'react';
// ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CustomTooltip from 'commons/components/Tooltip';

// router history
import { useHistory } from 'react-router-dom';
// utils
import { truthty } from 'utils/functions';

import '../../forms.css';

const Item = (form, classes, actions) => {
  const history = useHistory();
  const questionsQuantity = form.questions().length;

  return (
    <Grid container xs={12} sm={12}>
      <Grid item xs={12} sm={12}>
        <ListItem key={`form-${form.id}`}>
          <ListItemAvatar>
            <Avatar className={(questionsQuantity <= 0 || form.disabled) ? '' : classes.blue}>
              <AssignmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${form.name} ${form.disabled ? '(Oculto)' : ''}`}
            secondary={`${questionsQuantity} Preguntas`}
          />
          <div className="icon-container">
            <CustomTooltip title="Ocultar Formulario">
              <IconButton onClick={() => actions.updateForm(form.id, !form.disabled)} data-id={form.id} className="icon-hover">
                {form.disabled ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </CustomTooltip>
            <CustomTooltip title="Ver Formulario">
              <IconButton onClick={() => history.push(`/form?formid=${form.id}`)} data-id={form.id} className="icon-hover">
                <AssignmentIcon />
              </IconButton>
            </CustomTooltip>
            {/* <Tooltip title='Manipular Cuestionario' enterDelay={500} enterNextDelay={500}> */}
            <CustomTooltip title="Manipular Formulario">
              <IconButton onClick={() => actions.toggleQuestionsForm(form.id)} data-id={form.id} className="icon-hover">
                <SettingsIcon />
              </IconButton>
            </CustomTooltip>
            {/* <Tooltip title='Editar Nombre Cuestionario' {enterDelay={500} enterNextDelay={500}}> */}
            <CustomTooltip title="Editar Nombre Formulario">
              <IconButton onClick={() => actions.toggleEdit(form)} data-id={form.id} className="icon-hover">
                <EditIcon />
              </IconButton>
            </CustomTooltip>
            {/* <Tooltip title='Eliminar Cuestionario' enterDelay={500} enterNextDelay={500}> */}
            <CustomTooltip title="Eliminar Formulario">
              <IconButton onClick={() => actions.toggleDelete(form.id)} data-id={form.id} className="icon-hover">
                <DeleteIcon />
              </IconButton>
            </CustomTooltip>
          </div>
        </ListItem>
      </Grid>
    </Grid>
  );
};

const ItemList = (props) => {
  const { formsList, classes, actions } = props;
  const itemFormList = formsList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    .map((form) => Item(form, classes, actions));

  const itemFormListContent = (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={12}>
        <List>
          {itemFormList}
        </List>
      </Grid>
    </Grid>
  );

  const noFormMessage = (
    <Grid container item xs={12} alignItems="center" justify="center">
      <Typography variant="h6" component="h2">
        No se han ingresado formularios
      </Typography>
    </Grid>
  );

  const content = truthty(formsList)
    ? itemFormListContent
    : noFormMessage;

  return (
    <Grid item xs={12}>
      <Card className={classes.root}>
        <CardContent>
          {content}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ItemList;
